/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'
import { getPlans } from '../../redux/actions/planActions'
import { connect } from 'react-redux'
import FilterResults from 'react-filter-search'
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";


import { getAllPlans, deleteplanByid, reorderFitnessPlans } from '../../dataServices/Services'
function FitnessPlan({ history, plans, getPlans, auth }) {
	const [ loading, setLoading ] = useState(false)
	const [ searchValue, setsearchValue ] = useState('')
	const [ searchData, setSearchData ] = useState([])
	const fetchplans = useCallback(async () => {
		setLoading(true)
		const r = await getAllPlans()
		if (r.status === 200) {
			setLoading(false)

			getPlans(r.data.plans)
		} else {
			setLoading(false)
			alert('Network Error')
		}
	},[getPlans])
	useEffect(() => {
		fetchplans()
	}, [])


	const reorderPlans = async (currentOrder, newOrder) => {

		const data = {
			currentOrder:plans.plans[currentOrder].order,
			newOrder:plans.plans[newOrder].order,
		}
		await reorderFitnessPlans(data).then((data)=>{
			fetchplans()
		})
	}
	const handleClearSearch = () => {
    setsearchValue('');
	setSearchData([]);
  };


	return (
		<div>
			<div className="top-bar">
				{' '}
				<div className="top-bar-search">
					{' '}
					<div className="custom-input">
					<input
						type="text"
						name=""
						id=""
						placeholder="Search"
						aria-label="Search Fitness Plan by Title name"
						value={searchValue}
						onChange={(e) => {
							setsearchValue(e.target.value)
							setSearchData(plans.plans)
						}}
					/>
					<button onClick={handleClearSearch} aria-label="Clear search data">
					<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
<path d="M24.2037 3.88328C23.7481 3.42766 23.0094 3.42766 22.5538 3.88328L14.0437 12.3934L5.53362 3.88328C5.07801 3.42766 4.33931 3.42766 3.88371 3.88328C3.42809 4.33889 3.42809 5.07758 3.88371 5.53319L12.3938 14.0433L3.88373 22.5533C3.42811 23.009 3.42811 23.7476 3.88373 24.2033C4.33934 24.6589 5.07803 24.6589 5.53364 24.2033L14.0437 15.6932L22.5538 24.2033C23.0094 24.6589 23.7481 24.6589 24.2037 24.2033C24.6593 23.7476 24.6593 23.009 24.2037 22.5534L15.6936 14.0433L24.2037 5.53319C24.6593 5.07758 24.6593 4.33889 24.2037 3.88328Z"/>
</svg>
					</button>
					</div>
					{searchData && (
						<FilterResults
							value={searchValue}
							data={searchData}
							renderResults={(results) => (
								<div className='w-100'>
									{results.map((el) => (
										<div className="select-custom"
											onClick={(e) => {
												history.push(`/single-plan/${el._id}`)
												setSearchData([])
											}}
											onKeyPress={(e) => {
												history.push(`/single-plan/${el._id}`)
												setSearchData([])
											}}
										>
											<div>
												<p className='mb-0'
													tabIndex={0}
													
												>
													{el.title} : Category {el.category}
												</p>
											</div>
										</div>
									))}
								</div>
							)}
						/>
					)}
				</div>
			</div>
				<div className="m-4 p-4">
					<h1 tabIndex={0} style={{ display: 'block' }} aria-label="Classrooms">
						Welcome to Fitness Plans
					</h1>{' '}
					<p tabIndex={0}>
						Each fitness plan is designed with a primary objective in mind, and
						around that main goal is built a well rounded routine. Use these
						plans as a guide on your fitness journey
					</p>
					{auth.user.isTrainer && (
						<button
							onClick={(e) => {
								e.preventDefault()
								history.push('/add-fitness-plan')
							}}
							className="btn btn-primary"
						>
							Add Fitness Plan
						</button>
					)}
					<h2 tabIndex={0}>List of Fitness Plans</h2>
					{loading && (
							<div
								class="spinner-border text-primary ml-auto mr-auto"
								role="status"
							>
								<span class="sr-only">Loading...</span>
							</div>
						)}
					<div>
						{plans &&
							plans.plans.map((plan, index) => (
								<div
									className="text-align-center"
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									{' '}
									<button
										onClick={() => {
											history.push(`/single-plan/${plan._id}`)
										}}
										className="btn btn-primary m-2"
										aria-label={`${plan.title}-position ${index+1}`}
										style={{
											marginTop: '5px',
											width: '90%',
										}}
										tabIndex={0}
									>
										{plan.title}
									</button>
									{auth.user.isTrainer && (
										<>{index !== 0 && (
											<button
												aria-label="Move Up The Plan"
												className="btn btn-danger m-2"
												onClick={() => reorderPlans(index, index - 1)}
											>
												<ArrowUpOutlined />
											</button>
										)}
											{index !== (plans.plans.length - 1) && (
												<button
													aria-label="Move Down The Plan"
													className="btn btn-danger m-2"
													onClick={() => reorderPlans(index, index + 1)}
												>
													<ArrowDownOutlined />
												</button>
											)}
											<button
												aria-label="delete plan"
												className="btn btn-danger m-2"
												onClick={async (e) => {
													setLoading(true)
													e.preventDefault()
													const r = await deleteplanByid(plan._id)

													if (r.status === 200) {
														setLoading(false)
														alert(r.data.message)
														fetchplans()
													} else {
														alert('Network Error')
													}
												}}
											>
												<i className="fa fa-trash"></i>
											</button>
										</>
									)}
									{auth.user.isTrainer && (
										<button
											aria-label="Edit plan"
											className="btn btn-danger m-2"
											onClick={(e) => {
												history.push(`/edit-plan/${plan._id}`)
											}}
										>
											<i className="fa fa-pencil"></i>
										</button>
									)}
								</div>
							))}
						{plans && plans.plans.length === 0 && (
							<div style={{ marginTop: 30, fontSize: 30 }} tabIndex={0}>
								No Fitness Plan Found
							</div>
						)}
					</div>
				</div>
		</div>
	)
}

const mapDispatchToProps = (dispatch) => {
	return {
		getPlans: (plans) => {
			dispatch(getPlans(plans))
		},
	}
}

const mapStateToProps = (state) => {
	return {
		plans: state.plans,
		auth: state.auth,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(FitnessPlan)
