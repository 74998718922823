import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import backIcon from "../../assets/Images/back.svg";
import { getmyRecommendation } from "../../dataServices/Services";
import { Collapse, Spin } from 'antd';
const { Panel } = Collapse;


function Recommendations() {
  const history = useHistory();
  const { state } = useLocation()
  const { id, selectedGoals } = state || {};
  const [recommendation, setRecommendation] = useState(null);


  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const response = await getmyRecommendation(id);
          setRecommendation(response.data);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };


      fetchData();
    }

  }, [id]);

  const handleBack = () => {
    history.goBack(); // Navigate back to the previous page
  };

  return (
    <>
      {recommendation ? (
        <div className="recomended-page">
          <h1 tabIndex="0">Recommendations</h1>

          <section className="mydata-stats" aria-labelledby="welcome-heading">
            <h4 tabIndex="0" className="mydata-stats-title">{recommendation.name}</h4>
            {
              (id === 8 && selectedGoals.length !== 0) ? (
                <section className="mydata-stats" aria-labelledby="welcome-heading">
                  <Collapse accordion>
                    {
                      selectedGoals && selectedGoals.map((goalDetails) => (
                        <Panel header={`${goalDetails.goalName}`} key={goalDetails.id}>
                          <div tabIndex={0}
                            dangerouslySetInnerHTML={{ __html: goalDetails.content }}
                          />
                        </Panel>
                      ))
                    }

                  </Collapse>
                </section>
              ) : (
                <p tabIndex="0">{recommendation.description}</p>
              )
            }
          </section>
          <button tabIndex="0" type="button" className="btn btn-primary mt-2" onClick={handleBack}>
            <img className="white mr-2" src={backIcon} alt=""></img>
            Back
          </button>
        </div >
      ) : (
        <div className="loader">
          <Spin />
        </div>
      )
      }
    </>
  );
}

export default Recommendations;
