/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import '../../assets/css/classrooms.css'
import { Button, Space, Table, Tag } from 'antd';

import { commentsAction, getAllReportedComments } from '../../dataServices/Services'

function ReportedComments() {

    const [comments, setComments] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const fetchReportedComments = async () => {
        setIsLoading(true)
        const response = await getAllReportedComments()
        setComments(response.data)
        setIsLoading(false)
    }

    useEffect(() => {
        fetchReportedComments()
    }, [])

    const handleCommentsAction = async (actionType, record) => {
        const commentsActionBody = {
            commentId: record.commentId,
            sectionId: record.sectionId,
            actionType: actionType
        }
        await commentsAction(commentsActionBody)
        fetchReportedComments()
    }

    const columns = [
        {
            title: 'Comment',
            dataIndex: 'commentText',
            key: 'commentText',
        },
        {
            title: 'Commented By',
            dataIndex: 'commentedBy',
            key: 'commentedBy',
        },
        {
            title: 'Section Name',
            dataIndex: 'sectionName',
            key: 'sectionName',
        },
        {
            title: "Action",
            dataIndex: "",
            width: "10%",
            key: "x",
            render: (text, record) => (
                <div
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <button
                        title='Keep Comment'
                        aria-label='keep comment'
                        className="btn btn-danger m-2"
                        onClick={() => handleCommentsAction(0, record)}
                    >
                        <i class="fa fa-check"></i>
                    </button>
                    <button
                        title='Delete Comment'
                        aria-label='delete comment'
                        className="btn btn-danger m-2"
                        onClick={() => handleCommentsAction(1, record)}
                    >
                        <i className="fa fa-trash"></i>
                    </button>
                </div>
            ),
        },
    ];


    return (
        <div className="m-4 p-4">
            <h1 tabIndex={0} style={{ display: 'block' }} aria-label="Reported Comments">
                Reported Comments
            </h1>{' '}
            <Table
                pagination={false}
                loading={isLoading}
                dataSource={comments}
                columns={columns} />
        </div>
    )
}

export default ReportedComments


