/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons"
import React, { useEffect, useRef, useState } from 'react'
import FilterResults from 'react-filter-search'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import '../../assets/css/classrooms.css'
import { deleteEquipements, getRecommendedEquipmentService, reorderEquipmentsService } from '../../dataServices/Services'
import { getRecommendedEquipments } from '../../redux/actions/EquipmentsActions'

function RecommendedEquipments({ getEquipments, equipments, auth }) {

    const history = useHistory()
    const [searchValue, setsearchValue] = useState('')
    const [searchData, setSearchData] = useState([])
    const [loading, setLoading] = useState(false)
    const [equipment, setEquipment] = useState([])
    const [categories, setCategories] = useState([])
    const { addToast } = useToasts()
    const toastRef = useRef();
    const equipmentRef = useRef();

    const fetchEquipments = async () => {
        setLoading(true)
        let token = localStorage.getItem('token')
        if (token !== null) {
            let r = await getRecommendedEquipmentService()
            if (r.data && r.data.equipmentsData) {
                getEquipments(r.data.equipmentsData)
                setEquipment(r.data.equipmentsData)
                setLoading(false)
            }
        } else {
            setLoading(false)
            addToast('Resources not loaded', { appearance: 'error' })
        }
    }

    const deleteResource = async (event, resourceId) => {
        try {
            event.preventDefault();
            const response = await deleteEquipements(resourceId)

            alert(response.data.success)
            fetchEquipments()
        } catch (error) {
            addToast(<span ref={toastRef}
                aria-label={error.message}
                tabIndex={0}>{error.message}</span>, {
                appearance: 'error',
                autoDismiss: true,
            })
            toastRef.current.focus()
        }
    }

    const getCategory = () => {
        let cat = []
        for (const element of equipment) {
            if (equipment.length > 0) {
                if (cat.indexOf(element.category.toLowerCase()) === -1) {
                    cat.push(element.category.toLowerCase())
                }
            }
        }

        setCategories(cat)
    }
    useEffect(() => {
        fetchEquipments()
    }, [])

    useEffect(() => {
        getCategory()
    }, [equipment])

    const reorderEquipments = async (currentOrder, newOrder) => {
        const data = {
            currentOrder: equipments.equipments[currentOrder].order,
            newOrder: equipments.equipments[newOrder].order
        }
        await reorderEquipmentsService(data).then((data) => {
            fetchEquipments()
        })
    }

    const handleClearSearch = () => {
    setsearchValue('');
    setSearchData([]);
  };



    return (
        <div>
            {' '}
            <div className="top-bar">
                {' '}
                <div className="top-bar-search">
                    {' '}
                    <div className="custom-input mr-3">
                    <input
                        type="text"
                        name=""
                        id=""
                        value={searchValue}
                        placeholder="Search"
                        aria-label="Search product name"
                        onChange={(e) => {
                            setsearchValue(e.target.value)
                            setSearchData(equipments.equipments)
                        }}
                    />
                    <button onClick={handleClearSearch} aria-label="Clear search data">
					<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
<path d="M24.2037 3.88328C23.7481 3.42766 23.0094 3.42766 22.5538 3.88328L14.0437 12.3934L5.53362 3.88328C5.07801 3.42766 4.33931 3.42766 3.88371 3.88328C3.42809 4.33889 3.42809 5.07758 3.88371 5.53319L12.3938 14.0433L3.88373 22.5533C3.42811 23.009 3.42811 23.7476 3.88373 24.2033C4.33934 24.6589 5.07803 24.6589 5.53364 24.2033L14.0437 15.6932L22.5538 24.2033C23.0094 24.6589 23.7481 24.6589 24.2037 24.2033C24.6593 23.7476 24.6593 23.009 24.2037 22.5534L15.6936 14.0433L24.2037 5.53319C24.6593 5.07758 24.6593 4.33889 24.2037 3.88328Z"/>
</svg>
					</button>
                    </div>
                    {' '}
                    <select className="dropdown-new"
                        name=""
                        id=""
                        aria-label="Search Product by category "
                        value={searchValue}
                        onChange={(e) => {
                            setsearchValue(e.target.value)
                            setSearchData(equipments.equipments)
                        }}
                        
                    >
                        {categories &&
                            categories.map((cat) => <option value={cat}>{cat}</option>)}
                    </select>
                    {searchData && (
                        <FilterResults
                            value={searchValue}
                            data={searchData}
                            renderResults={(results) => (
                                <div className="w-100">
                                    {results.map((el) => (
                                        <div className="select-custom"
                                            onClick={(e) => {
                                                history.push(`/recommended-equipments/${el._id}`)
                                                setSearchData([])
                                            }}
                                            onKeyPress={(e) => {
                                                history.push(`/recommended-equipments/${el._id}`)
                                                setSearchData([])
                                            }}
                                        >
                                            <div>
                                                <p className="mb-0"
                                                    tabIndex={0}
                                                    
                                                >
                                                    {el.title} : Category {el.category}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        />
                    )}
                </div>
            </div>
            <div className="m-4 p-4">
                <h1 tabIndex={0} style={{ display: 'block' }}>
                    Welcome into the recommended products.
                </h1>{' '}
                <p tabIndex={0}>
                    Here you can find useful health and fitness products recommended for their quality and accessibility. Note, as an Amazon Associate I earn from qualifying purchases, some of the products  in this section may contain associate links.
                </p>
                {auth.user.isTrainer && (
                    <button
                        onClick={(e) => {
                            history.push('/add-recommended-equipments')
                        }}
                        className="btn btn-primary"
                    >
                        Add Recommended Products
                    </button>
                )}
                <h2 tabIndex={0}>Recommended Products</h2>

                {equipments.equipments.length ? (
                    <div className="p-4 m-2">
                        <div
                            aria-label="List of Recommended Products"
                            className="list-group"
                        >
                            {loading && (
                                <div
                                    class="spinner-border text-primary ml-auto mr-auto"
                                    role="status"
                                >
                                    <span class="sr-only">Loading...</span>
                                </div>
                            )}
                            {!loading &&
                                equipments &&
                                !auth.user.isTrainer &&
                                equipments.equipments.map((c) => {
                                    return (
                                        <button 
                                            key={c._id}
                                            id="equipment"
                                            ref={equipmentRef}
                                            tabIndex={0}
                                            onClick={() => {
                                                history.push(`/recommended-equipments/${c._id}`)
                                            }}
                                            className="btn btn-primary m-2"
                                            style={{
                                                marginTop: '5px',
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            {c.title}
                                        </button>
                                    )
                                })}
                            {equipments &&
                                auth.user.isTrainer &&
                                equipments.equipments.map((c, index) => {
                                    return (
                                        <div
                                            className="text-align-center"
                                            style={{ display: 'flex' }}
                                        >
                                            {' '}
                                            <button
                                                key={c._id}
                                                ref={equipmentRef}
                                                tabIndex={0}
                                                onClick={() => {
                                                    history.push(`/recommended-equipments/${c._id}`)
                                                }}
                                                className="btn btn-primary m-2"
                                                aria-label={`${c.title}-position ${index + 1}`}
                                                style={{
                                                    marginTop: '5px',
                                                    width: '90%',
                                                }}
                                            >
                                                {c.title}
                                            </button>
                                            {auth.user.isTrainer && (
                                                <>
                                                    {index !== 0 && (
                                                        <button
                                                            aria-label="Move Up The product"
                                                            className="btn btn-danger m-2"
                                                            onClick={() => reorderEquipments(index, index - 1)}
                                                        >
                                                            <ArrowUpOutlined />
                                                        </button>
                                                    )}
                                                    {index !== (equipments.equipments.length - 1) && (
                                                        <button
                                                            aria-label="Move Down The product"
                                                            className="btn btn-danger m-2"
                                                            onClick={() => reorderEquipments(index, index + 1)}
                                                        >
                                                            <ArrowDownOutlined />
                                                        </button>
                                                    )}
                                                    <button
                                                        aria-label="delete product"
                                                        className="btn btn-danger m-2"
                                                        onClick={(e) => deleteResource(e, c._id)}
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </button>
                                                </>
                                            )}
                                            {auth.user.isTrainer && (
                                                <button
                                                    aria-label="Edit product"
                                                    className="btn btn-danger m-2"
                                                    onClick={(e) => {
                                                        history.push(`/editEquipments/${c._id}`)
                                                    }}
                                                >
                                                    <i className="fa fa-pencil"></i>
                                                </button>
                                            )}
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                ) :
                    ""}

            </div>
        </div>
    )
}
const mapDispatchToProps = (dispatch) => {
    return {
        getEquipments: (equipments) => {
            dispatch(getRecommendedEquipments(equipments))
        },
    }
}
const mapStateToProps = (state) => ({
    equipments: state.equipments,
    auth: state.auth,
})
export default connect(mapStateToProps, mapDispatchToProps)(RecommendedEquipments)
