import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadUser, getmyStats } from "../../dataServices/Services";
import { loadUserAction } from "../../redux/actions/authActions";
import { NETWORK_ERROR } from "../../constants/Constants";
import "../../assets/css/mydata.css";
import { Spin, Button, Popconfirm } from "antd";
import { useHistory } from "react-router-dom";
import {
  CloseOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { Tooltip } from "antd";
import { styled } from "@material-ui/core";

function MyStats() {
  const history = useHistory();
  const [userData, setUserData] = useState(null);
  const [statsData, setStatsData] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const response = await loadUser(token);
          if (response !== NETWORK_ERROR) {
            const user = response.data.user;
            console.log("User data:", user?._id);
            setUserData(user);
            dispatch(loadUserAction(user)); // Update Redux store with user data
            if (user?._id) {
              console.log('userr', user)
              const resp = await getmyStats(user?._id);
              setStatsData(resp.data);
              console.log("response", resp.data);
            }
          } else {
            console.error("Error loading user data:", response);
          }
        } catch (error) {
          console.error("Error loading user data:", error);
        }
      }
    }

    fetchData();
  }, [dispatch]);

  const handleConfirm = () => {
    // Handle the confirm action here
    console.log('Confirmed!');
  };

  const handleCancel = () => {
    // Handle the cancel action here
    console.log('Cancelled!');
  };

  const iconclose = <CloseOutlined />;


  return (
    <>
      {/* <div className="loader">
    <Spin />
  </div> */}

      <div className="mydata-page">
        <h1 tabIndex="0">My Stats</h1>

        <div className="mydata-profile">
          {/* <ProfilePic
            image={DemoPic}
            roundedColor="#321124"
            imageWidth="150"
            imageHeight="150"
            roundedSize="13"
          /> */}
          <div className="mydata-profile-info">
            <h4 tabIndex="0">{userData?.firstName}</h4>
            <email tabIndex="0">{userData?.email}</email>
          </div>
        </div>

        <article className="mydata-stats" aria-labelledby="welcome-heading">
          <h4 tabIndex="0" className="mydata-stats-title">
            Welcome to the “My Stats” page
          </h4>
          <p tabIndex="0">
            Where you can find some more information about you and some tips on how best to accomplish your
            fitness Goals and find the healthiest version of you. All of the information in this section is
            intended to serve as a guide only, and any decisions you make about your own activity level,
            nutritional regiment, or any other factor related to your health and well-being,
            should be consulted with your personal care physician first and foremost.
          </p>
        </article>

        <section>
          <hr />
        </section>

        <article className="mydata-stats">
          <h4 tabIndex="0" className="mydata-stats-title">
            My Stats
          </h4>
          <p tabIndex="0">
            Based on the data you have provided,
            here is some information that may be useful to you on your fitness journey.
            Remember that these are just data points to give you a snapshot of where you are currently,
            and that you have the power to make change in your life if you so choose, you are worth it.
          </p>
        </article>

        <section className="stats-card-wrap">
          {/* <Spin /> */}
          {statsData ? (
            <>
              <div tabIndex="0" className="stats-card">
                <div className="rfc-info-container">
                  <Tooltip trigger="focus" placement="topLeft" color="white" title={
                    <span aria-live="polite">
                      The Body Mass Index (BMI) is a system used by many physicians and organizations to easily determine if you may be at higher risk for health complications based on your weight. It is determined by comparing how tall you are (your height) to how heavy you are (your weight). This is a useful number for the general population, and can be an effective tool for monitoring your own health risk, but it is important to understand that there are many factors that go into an overall picture of health, and this number is just one of them. In other words, if your BMI is not where you would like it to be, don’t panic, just understand that you have the power to make change if you so choose, and ultimately your optimal health and well-being is the goal…you are worth the effort. Note, your BMI is not the same as your Body Fat Percentage, which is a measure of how much of your overall weight is comprised of body fat.
                    </span>
                  }>
                    <Button type="text">
                      <i className="fas fa-info-circle" />
                      <span style={{ marginLeft: 5 }}>Info</span>
                    </Button>
                  </Tooltip>
                </div>
                <p className="stats-card-title">BMI (Body Mass Index) </p>
                <div className="stats-card-content">
                  {statsData.userBMI ? (
                    <>
                      <p className="stats-card-info top-content">Your BMI is</p>
                      <p className="stats-card-value m-0">{statsData.userBMI}</p>
                    </>
                  ) : (<>
                    <p className="stats-card-value"><span>Not Enough Data</span></p>
                  </>)}
                </div>
              </div>
              <div tabIndex="0" className="stats-card">
                <div className="rfc-info-container">
                  <Tooltip trigger="focus" placement="topLeft" color="white" title={
                    <span aria-live="polite">
                      {"Knowing your BMI category helps determine the best health plan, as categories indicate risks for issues like hypertension, diabetes, and high cholesterol: underweight (BMI < 18), healthy (BMI 18-25), overweight (BMI 25-30), and obese (BMI > 30). Be kind to yourself, consult your physician for a personalized plan, and strive for better health, noting that athletes may have a higher BMI due to muscle mass, which doesn't reflect actual health risk"}
                    </span>}>
                    <Button type="text">
                      <i className="fas fa-info-circle" />
                      <span style={{ marginLeft: 5 }}>Info</span>
                    </Button>
                  </Tooltip>

                </div>
                <p className="stats-card-title">BMI Category </p>
                <div className="stats-card-content">
                  {statsData.BMI_category.category ? (
                    <>
                      <p className="stats-card-value">
                        {statsData.BMI_category.category}
                      </p>
                      <p className="stats-card-info bottom-content">
                        {statsData.BMI_category.range}
                      </p>
                    </>
                  ) : (<>
                    <p className="stats-card-value"><span>Not Enough Data</span></p>
                  </>)}

                </div>
              </div>
              <div tabIndex="0" className="stats-card">
                <div className="rfc-info-container">
                  <Tooltip trigger="focus" placement="topLeft" color="white" title={
                    <span aria-live="polite">
                      {"The ideal body weight is the range at which, based on the Body Mass Index, you would be considered at a healthy weight for your height. This is a fairly wide range, and it is important to remember that this is an estimated value based on studies done on optimal health and weight. This is a single factor among several health indicators, and may not always apply well to every person. For example, if you have a larger bone structure or are a performance athlete with an above normal amount of muscle mass, this number may be less applicable. A 6 foot tall male weighing 200 pounds is technically outside the ideal body weight range, but if that person is an athlete with a low body fat percentage and a high activity level, then the ideal body weight range does not apply so well. It’s best to have this number and compare it along side other health markers, such as how well you sleep, how active you are, fitness performances, blood work tests, etc., and through analysis and discussion with your Personal Care Physician, determine what the true ideal body weight for you might be."}
                    </span>}>
                    <Button type="text">
                      <i className="fas fa-info-circle" />
                      <span style={{ marginLeft: 5 }}>Info</span>
                    </Button>
                  </Tooltip>
                </div>
                <p className="stats-card-title">Ideal Body Weight Range (BMI) </p>
                <div className="stats-card-content">
                  {statsData.idealBodyWeightRange ? (
                    <>
                      <p className="stats-card-value">
                        {statsData.idealBodyWeightRange}
                      </p>
                      <p className="stats-card-info bottom-content">
                        Current Weight {statsData.currentWeight} lbs
                      </p>
                    </>
                  ) : (<>
                    <p className="stats-card-value"><span>Not Enough Data</span></p>
                  </>)}

                </div>
              </div>
              <div tabIndex="0" className="stats-card">
                <div className="rfc-info-container">
                  <Tooltip trigger="focus" placement="topLeft" color="white" title={
                    <span aria-live="polite">
                      {"The Basal Metabolic Rate (BMR) is the number of calories your body needs to perform basic life functions like pumping your heart and breathing, even if you rest all day. Knowing your BMR is important when tracking caloric intake; consistently eating below this amount can slow your metabolism and cause health issues. Note that BMR is an estimate and varies with factors like muscle mass, which increases BMR since muscle burns more calories at rest. Increasing muscle can help in losing body fat effectively"}
                    </span>}>
                    <Button type="text">
                      <i className="fas fa-info-circle" />
                      <span style={{ marginLeft: 5 }}>Info</span>
                    </Button>
                  </Tooltip>
                </div>
                <p className="stats-card-title">BMR (Basal Metabolic Rate) </p>
                <div className="stats-card-content">
                  {statsData.BMR ? (
                    <>
                      <p className="stats-card-value">
                        {statsData.BMR} <span>cal/day</span>
                      </p>
                    </>) : (<>
                      <p className="stats-card-value"><span>Not Enough Data</span></p>
                    </>)}

                </div>
              </div>
              <div tabIndex="0" className="stats-card">
                <div className="rfc-info-container">
                  <Tooltip trigger="focus" placement="topLeft" color="white" title={
                    <span aria-live="polite">
                      {"Your Caloric Consumption Rate is an estimated value found by combining your BMR and average activity level, which gives you an approximate number of Calories you are burning in a day total. This number can vary a bit, depending on your actual activity for the day, and should be considered just a guide line toward determining how much energy you may want to take in through food and drink on a daily basis"}
                    </span>}>
                    <Button type="text">
                      <i className="fas fa-info-circle" />
                      <span style={{ marginLeft: 5 }}>Info</span>
                    </Button>
                  </Tooltip>
                </div>
                <p className="stats-card-title" aria-label="Calorie Consumption Rate">Cal: Consumption Rate </p>
                <div className="stats-card-content">
                  {statsData.caloricConsumptionRate !== "" ? (<>
                    <p className="stats-card-info top-content">Total Daily Energy Expenditure</p>
                    <p className="stats-card-value">
                      {statsData.caloricConsumptionRate} <span>cal/day</span>
                    </p></>) : (<>
                      <p className="stats-card-value"><span>Not Enough Data</span></p>
                    </>)}

                </div>
              </div>
              <div tabIndex="0" className="stats-card">
                <div className="rfc-info-container">
                  <Tooltip trigger="focus" placement="topLeft" color="white" title={
                    <span aria-live="polite">
                      {"Based on your BMR, activity level, current weight, and goal weight, here is a general daily calorie intake recommendation. This estimate may vary with factors like daily activity. For instance, eat slightly less on sedentary days. Whether aiming to gain or lose weight, your calorie intake should be close to your maintenance level to avoid negative health effects. Always fuel your body well"}
                    </span>}>
                    <Button type="text">
                      <i className="fas fa-info-circle" />
                      <span style={{ marginLeft: 5 }}>Info</span>
                    </Button>
                  </Tooltip>
                </div>
                <p className="stats-card-title" aria-label="Recommended Calorie Intake">Rec: Calorie Intake </p>
                <div className="stats-card-content">
                  {statsData.recommendedCalorieIntake !== "" ? (
                    <>
                      <p className="stats-card-info top-content">Total Daily Calorie Intake</p>
                      <p className="stats-card-value">
                        {statsData.recommendedCalorieIntake} <span>cal/day</span>
                      </p>
                    </>
                  ) : (<>
                    <p className="stats-card-value"><span>Not Enough Data</span></p>
                  </>)}
                </div>
              </div>
              <div tabIndex="0" className="stats-card">
                <p className="stats-card-title">Weight Change </p>
                <div className="stats-card-content">
                  {statsData.weightChangePerWeek !== "" ? (
                    <>
                      <p className="stats-card-info top-content">Weight Change Per Week</p>
                      <p className="stats-card-value">
                        {statsData.weightChangePerWeek} <span>lbs/week</span>
                      </p>
                    </>
                  ) : (<>
                    <p className="stats-card-value"><span>Not Enough Data</span></p>
                  </>)}
                </div>
              </div>
              <div tabIndex="0" className="stats-card">
                <p className="stats-card-title">Time to Goal Weight</p>
                <div className="stats-card-content">
                  {statsData.timeToGoalWeight !== "" ? (
                    <>
                      <p className="stats-card-info top-content">Target</p>
                      <p className="stats-card-value">
                        {statsData.timeToGoalWeight} <span>weeks</span>
                      </p>
                    </>) : (<>
                      <p className="stats-card-value"><span>Not Enough Data</span></p>
                    </>)}
                </div>

              </div>
            </>
          ) : (
            <div className="loader">
              <Spin />
            </div>
          )}
        </section>
        <div className="fitness-test mb-4">
          <h4 tabIndex="0" className="mydata-stats-title text-left ml-0">
            Fitness Test
          </h4>

          <section className="stats-card-wrap m-0">
            <div tabIndex="0" className="stats-card">
              <p className="stats-card-title">1 Min Push-up Fitness Test (assesses general upper body strength)</p>
              <div className="stats-card-content">
                {statsData?.pushUpTestResult?.pushUpTest ? (
                  <>
                    <p className="stats-card-value">
                      {statsData?.pushUpTestResult?.pushUpTest}<span> Repetitions</span>
                    </p>
                    <p className="stats-card-info bottom-content">{statsData?.pushUpTestResult?.result}</p>
                  </>) : (<>
                    <p className="stats-card-value"><span>Not Enough Data</span></p>
                  </>)}
              </div>
            </div>
            <div tabIndex="0" className="stats-card">
              <p className="stats-card-title">1 Min Curl-up Fitness Test (assesses general abdominal strength)</p>
              <div className="stats-card-content">
                {statsData?.curlUpTestResult?.curlUpTest ? (
                  <>
                    <p className="stats-card-value">
                      {statsData?.curlUpTestResult?.curlUpTest}<span> Repetitions</span>
                    </p>
                    <p className="stats-card-info bottom-content">{statsData?.curlUpTestResult?.result}</p>

                  </>) : (<>
                    <p className="stats-card-value"><span>Not Enough Data</span></p>
                  </>)}
              </div>
            </div>
            <div tabIndex="0" className="stats-card">
              <p className="stats-card-title">1 Min Squat Fitness Test (assesses general lower body strength)</p>
              <div className="stats-card-content">
                {statsData?.squatTestResult?.squatTest ? (<>
                  <p className="stats-card-value">
                    {statsData?.squatTestResult?.squatTest}<span> Repetitions</span>
                  </p>
                  <p className="stats-card-info bottom-content">{statsData?.squatTestResult?.result}</p>

                </>) : (<>
                  <p className="stats-card-value"><span>Not Enough Data</span></p>
                </>)}
              </div>
            </div>
            <div tabIndex="0" className="stats-card">
              <p className="stats-card-title">3 Minute YMCA Cardio Fitness Test (assesses general endurance and efficiency of cardiovascular system)</p>
              <div className="stats-card-content">
                {statsData?.cardioTestResult?.cardioTest ? (<>
                  <p className="stats-card-value">
                    {statsData?.cardioTestResult?.cardioTest} <span> Beats Per Minute</span>
                  </p>
                  <p className="stats-card-info bottom-content">{statsData?.cardioTestResult?.result}</p>

                </>
                ) : (<>
                  <p className="stats-card-value"><span>Not Enough Data</span></p>
                </>)}
              </div>
            </div>
          </section>
        </div>
      </div >

      <button
        type="button"
        className="btn btn-primary"
        onClick={() =>
          history.push({
            pathname: "/mydata",
            state: { userId: userData._id, email: userData?.email, userName: userData?.firstName },
          })
        }
      >
        My Data
      </button>
    </>
  );
}

export default MyStats;
