import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer
        className="text-black p-4 text-center"
        style={{ backgroundColor: "#e3f2fd" }}
      >
        Copyright &copy; {new Date().getFullYear()} ReVision Fitness by ReVision
        Training LLC
      </footer>
    );
  }
}
export default Footer;
