import React from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import Routes from '../constants/routes'
import "../assets/css/navbar.css";
function Navbar({ auth }) {
	const history = useHistory()

	return (
		<div>
			<nav
				className="navbar navbar-expand-sm navbar-light"
				style={{ backgroundColor: '#e3f1fd' }}
			>
				<div className="d-flex">
					<Link className="navbar-brand">
						{' '}
						<img
							className="img-fluid rounded"
							width={100}
							height={100}
							alt="ReVision Training LLC Logo"
							src={require('../assets/Logo1.png')}
						></img>
					</Link>

					<button
						className="navbar-toggler"
						type="button"
						aria-label="Menu Toggle Button"
						data-toggle="collapse"
						data-target="#mobile-nav"
						id="toggleButton"
					>
						<span className="navbar-toggler-icon"></span>
					</button>

					<div >
						<ul className="navbar-nav mr-auto"> </ul>
						<ul className="navbar-nav ml-auto flex-style">
							<li className="nav-item">
								<Link
									to="/dashboard"
									className="btn button-style  m-1 border-danger"
									onClick={(e) => {
										e.preventDefault()
										document.getElementById('toggleButton').click()

										history.push('/dashboard')
									}}
								>
									Dashboard
								</Link>
							</li>
							<li className="nav-item">
								<Link
									to="/classrooms"
									className="btn button-style  m-1 border-danger"
									onClick={(e) => {
										e.preventDefault()
										document.getElementById('toggleButton').click()

										history.push('/classrooms')
									}}
								>
									{' '}
									Classrooms
								</Link>
							</li>
							<li className="nav-item">
								<Link
									to="/studios"
									className="btn button-style  m-1 border-danger"
									onClick={(e) => {
										e.preventDefault()
										document.getElementById('toggleButton').click()

										history.push('/studios')
									}}
								>
									{' '}
									Fitness Studio
								</Link>
							</li>{' '}

							<li className="nav-item">
								<Link
									to="/health-and-fitness"
									className="btn button-style  m-1 border-danger"
									onClick={(e) => {
										e.preventDefault()
										document.getElementById('toggleButton').click()

										history.push('/health-and-fitness')
									}}
								>
									{' '}
									Health And Fitness Guides
								</Link>
							</li>{' '}

							<li className="nav-item">
								<Link
									to="/faq"
									className="btn button-style  m-1 border-danger"
									onClick={(e) => {
										e.preventDefault()
										document.getElementById('toggleButton').click()

										history.push('/faq')
									}}
								>
									{' '}
									FAQ
								</Link>
							</li>{' '}

							<li className="nav-item">
								<Link
									to="/recommended-equipments"
									className="btn button-style  m-1 border-danger"
									onClick={(e) => {
										e.preventDefault()
										document.getElementById('toggleButton').click()

										history.push('/recommended-equipments')
									}}
								>
									{' '}
									Recommended Product
								</Link>
							</li>{' '}
							<li className="nav-item">
								<Link
									className="btn button-style  m-1 border-danger"
									to="/fitness-plans"
									onClick={(e) => {
										e.preventDefault()
										document.getElementById('toggleButton').click()
										history.push('/fitness-plans')
									}}
								>
									{' '}
									Fitness Plans
								</Link>
							</li>{' '}
							<li className="nav-item">
								<Link
									to="/message-board"
									className="btn button-style  m-1 border-danger"
									onClick={(e) => {
										e.preventDefault()
										document.getElementById('toggleButton').click()
										history.push('/message-board')
									}}
								>
									Message Board
								</Link>
							</li>{' '}
							{auth.user.isTrainer &&
								<li className="nav-item">
									<Link
										to={Routes.pushNotification}
										className="btn button-style m-1 border-danger"
										onClick={(e) => {
											e.preventDefault()
											document.getElementById('toggleButton').click()

											history.push(Routes.pushNotification)
										}}
									>
										Push Notifications
									</Link>
								</li>
							}
							<li className="nav-item">
								<Link
									to={Routes.credits}
									className="btn button-style  m-1 border-danger"
									onClick={(e) => {
										e.preventDefault()
										document.getElementById('toggleButton').click()

										history.push(Routes.credits)
									}}
								>
									Credits
								</Link>
							</li>{' '}
							<li className="nav-item">
								<Link
									to="/favorites"
									className="btn button-style  m-1 border-danger"
									onClick={(e) => {
										e.preventDefault()
										document.getElementById('toggleButton').click()

										history.push('/favorites')
									}}
								>
									Favorites
								</Link>
							</li>{' '}
							<li className="nav-item">
								<Link
									to="/profile"
									className="btn button-style m-1 border-danger"
									onClick={(e) => {
										e.preventDefault()
										document.getElementById('toggleButton').click()

										history.push('/profile')
									}}
								>
									Profile
								</Link>
							</li>{' '}
							<li className="nav-item">
								<Link
									to="/contact-us"
									className="btn button-style m-1 border-danger"
									onClick={(e) => {
										e.preventDefault()
										document.getElementById('toggleButton').click()

										history.push('/contact-us')
									}}
								>
									Contact us
								</Link>
							</li>{' '}
							<li className="nav-item">
								<Link
									to='/mystats'
									className="btn button-style m-1 border-danger"
									onClick={(e) => {
										e.preventDefault()
										document.getElementById('toggleButton').click()

										history.push('/mystats')
									}}
								>
									My Stats
								</Link>
							</li>{' '}
							{auth.user.isTrainer && (
								<li className="nav-item">
									<Link
										to="/reported-comments"
										className="btn button-style  m-1 border-danger"
										onClick={(e) => {
											e.preventDefault()
											document.getElementById('toggleButton').click()

											history.push('/reported-comments')
										}}
									>
										{' '}
										Reported Comments
									</Link>
								</li>
							)}
						</ul>
					</div>
				</div>
			</nav>
		</div>
	)
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	}
}
export default connect(mapStateToProps)(Navbar)
