/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons"
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import '../../assets/css/classrooms.css'
import { deleteFaqCategory, getFaqService, reorderFaq } from '../../dataServices/Services'
import { getFaq } from '../../redux/actions/getFaq'

function Faq({ getFaq, faq, auth }) {

    const history = useHistory()
    const [ loading, setLoading ] = useState(false)
    const [ , setResources ] = useState([])
    const { addToast } = useToasts()
    const toastRef = useRef();

    const fetchFaq = async () => {
        setLoading(true)
        let token = localStorage.getItem('token')
        if (token !== null) {
            let r = await getFaqService()
            if (r.data && r.data.faqData) {
                getFaq(r.data.faqData)
                setResources(r.data.faqData)
                setLoading(false)
            }
        } else {
            setLoading(false)
            addToast('Faq not loaded', { appearance: 'error' })
        }
    }

    const deleteFaq = async (categoryId) => {
        try {
            const response = await deleteFaqCategory(categoryId)
            alert(response.data.success)
            fetchFaq()
        } catch (error) {
            addToast(<span ref={toastRef}
                aria-label={error.message}
                tabIndex={0}>{error.message}</span>, {
                appearance: 'error',
                autoDismiss: true,
            })
            toastRef.current.focus()
        }
    }

    useEffect(() => {
        fetchFaq()
    }, [])


    const reorderFaqCategory = async (currentOrder, newOrder) => {
        const data = {
            currentOrder: faq.faq[ currentOrder ].order,
            newOrder: faq.faq[ newOrder ].order
        }
        await reorderFaq(data).then((data) => {
            fetchFaq()
        })
    }

    return (
        <div>

            <div className="m-4 p-4">
                <h1 tabIndex={0} style={{ display: 'block' }}>
                    FAQ
                </h1>{' '}
                <p tabIndex={0}>
                    Here you will find answers to Frequently Asked Questions about Fitness, The ReVision Fitness Program, and more.
                </p>
                {auth.user.isTrainer && (
                    <button
                        onClick={(e) => {
                            history.push('/add-faq-category')
                        }}
                        className="btn btn-primary"
                    >
                        Add new FAQ Category
                    </button>
                )}

                <div className="p-4 m-2">
                    <div
                        aria-label="List of Faq category"
                        className="list-group"
                    >
                        {loading && (
                            <div
                                class="spinner-border text-primary ml-auto mr-auto"
                                role="status"
                            >
                                <span class="sr-only">Loading...</span>
                            </div>
                        )}
                        {!loading &&
                            faq &&
                            !auth.user.isTrainer &&
                            faq.faq.map((c) => {
                                return (
                                    <button
                                        key={c._id}
                                        onClick={() => {
                                            history.push(`/faq-category/${c._id}`)
                                        }}
                                        className="btn btn-primary m-2"
                                        style={{
                                            marginTop: '5px',
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                        }}
                                        tabIndex={0}
                                    >
                                        {c.title}
                                    </button>
                                )
                            })}
                        {faq &&
                            auth.user.isTrainer &&
                            faq.faq.map((c, index) => {
                                return (
                                    <div
                                        className="text-align-center"
                                        style={{ display: 'flex' }}
                                    >
                                        {' '}
                                        <button
                                            key={c._id}
                                            onClick={() => {
                                                history.push(`/faq-category/${c._id}`)
                                            }}
                                            className="btn btn-primary m-2"
                                            aria-label={`${c.title}-position ${index + 1}`}
                                            style={{
                                                marginTop: '5px',
                                                width: '90%',
                                            }}
                                            tabIndex={0}
                                        >
                                            {c.title}
                                        </button>
                                        {auth.user.isTrainer && (
                                            <>
                                                {index !== 0 && (
                                                    <button
                                                        aria-label="Move Up The Faq category"
                                                        className="btn btn-danger m-2"
                                                        onClick={() => reorderFaqCategory(index, index - 1)}
                                                    >
                                                        <ArrowUpOutlined />
                                                    </button>
                                                )}
                                                {index !== (faq.faq.length - 1) && (
                                                    <button
                                                        aria-label="Move Down The Faq category"
                                                        className="btn btn-danger m-2"
                                                        onClick={() => reorderFaqCategory(index, index + 1)}
                                                    >
                                                        <ArrowDownOutlined />
                                                    </button>
                                                )}
                                                <button
                                                    aria-label="delete Faq category"
                                                    className="btn btn-danger m-2"
                                                    onClick={() => deleteFaq(c._id)}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </>
                                        )}
                                        {auth.user.isTrainer && (
                                            <button
                                                aria-label="Edit Faq category"
                                                className="btn btn-danger m-2"
                                                onClick={(e) => {
                                                    history.push(`/edit-faq-category/${c._id}`)
                                                }}
                                            >
                                                <i className="fa fa-pencil"></i>
                                            </button>
                                        )}
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapDispatchToProps = (dispatch) => {
    return {
        getFaq: (faq) => {
            dispatch(getFaq(faq))
        },
    }
}
const mapStateToProps = (state) => ({
    faq: state.faq,
    auth: state.auth,
})
export default connect(mapStateToProps, mapDispatchToProps)(Faq)
