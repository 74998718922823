import {
  BASE_URL,
  CREATE_ACCOUNT_URL,
  LOAD_USER_URL,
  LOGIN_URL,
  NETWORK_ERROR,
} from "../constants/Constants";
import { logoutUserAction } from "../redux/actions/authActions";

import Axios from "axios";

Axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      const item = { ...config };
      item.headers.Authorization = `Bearer ${token}`;
      return item;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

Axios.interceptors.response.use(
  (responseInter) => responseInter,
  (error) => new Promise((resolve, reject) => {
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      window.location.reload();
      logoutUserAction();
    }
    reject(error);
  }),
);

export const signupService = async (formData) => {
  formData.subPlatform = "web";
  try {
    return await Axios.post(BASE_URL + CREATE_ACCOUNT_URL, formData);
  } catch (err) {
    return NETWORK_ERROR;
  }
};

export const loginService = async (userData) => {
  try {
    let r;
    await Axios.post(BASE_URL + LOGIN_URL, userData).then((res) => {
      r = res;
    });

    return r;
  } catch (error) {
    return error;
  }
};
export const loadUser = async () => {
  try {
    return await Axios.get(`${BASE_URL + LOAD_USER_URL}`);
  } catch {
    return NETWORK_ERROR;
  }
};
export const loadClassroomsService = async () => {
  try {
    return await Axios.get(`${BASE_URL}classrooms/`);
  } catch {
    return NETWORK_ERROR;
  }
};
export const loadSingleClassroom = async (id) => {
  try {
    return await Axios.get(`${BASE_URL}classrooms/${id}`);
  } catch {
    return NETWORK_ERROR;
  }
};
export const loadStudiosService = async () => {
  try {
    return await Axios.get(`${BASE_URL}studios/`);
  } catch {
    return NETWORK_ERROR;
  }
};

export const loadSingleStudio = async (id) => {
  try {
    return await Axios.get(`${BASE_URL}studios/${id}`);
  } catch {
    return NETWORK_ERROR;
  }
};

export const loadSingleStudioFav = async (userId, studioId) => {
  try {
    return await Axios.get(
      `${BASE_URL}studio/favorites/${studioId}/user/${userId}`
    );
  } catch {
    return NETWORK_ERROR;
  }
};

export const addStudioFav = async (fav) => {
  try {
    return await Axios.post(
      `${BASE_URL}studio/favorites/add`,
      fav
    );
  } catch {
    return NETWORK_ERROR;
  }
};
export const removeStudioFav = async (favId) => {
  try {
    return await Axios.delete(
      `${BASE_URL}studio/favorites/remove/${favId}`
    );
  } catch {
    return NETWORK_ERROR;
  }
};

export const loadSingleClassroomFav = async (userId, classroomId) => {
  try {
    return await Axios.get(
      `${BASE_URL}classrooms/favorites/${classroomId}/user/${userId}`
    );
  } catch {
    return NETWORK_ERROR;
  }
};
export const addClassroomFav = async (fav) => {
  try {
    return await Axios.post(
      `${BASE_URL}classrooms/favorites/add`,
      fav
    );
  } catch {
    return NETWORK_ERROR;
  }
};
export const removeClassroomFav = async (favId) => {
  try {
    return await Axios.delete(
      `${BASE_URL}classrooms/favorites/remove/${favId}`
    );
  } catch {
    return NETWORK_ERROR;
  }
};

export const loadAllStudioFav = async (userId) => {
  try {
    return await Axios.get(
      `${BASE_URL}studio/favorites/user/${userId}`
    );
  } catch {
    return NETWORK_ERROR;
  }
};

export const getmyStats = async (userId) => {
  try {
    return await Axios.get(
      `${BASE_URL}mydata/getmyStats/${userId}`
    );
  } catch {
    return NETWORK_ERROR;
  }
};

export const getmyData = async (userId) => {
  try {
    return await Axios.get(
      `${BASE_URL}mydata/get/${userId}`
    );
  } catch {
    return NETWORK_ERROR;
  }
};

export const getmyRecommendation = async (userId) => {
  try {
    return await Axios.get(
      `${BASE_URL}mydata/getRecommendation/${userId}`
    );
  } catch {
    return NETWORK_ERROR;
  }
};

export const updatemyData = async (userId, data) => {
  try {
    return await Axios.put(`${BASE_URL}mydata/update/${userId}`, data);
  } catch (error) {
    return { error };
  }
}

export const loadAllClassroomFav = async (userId) => {
  try {
    return await Axios.get(
      `${BASE_URL}classrooms/favorites/user/${userId}`
    );
  } catch {
    return NETWORK_ERROR;
  }
};
export const loadAllMessagesService = async () => {
  try {
    return await Axios.get(`${BASE_URL}messages/`);
  } catch {
    return NETWORK_ERROR;
  }
};
export const loadSingleMessageService = async (id) => {
  try {
    return await Axios.get(`${BASE_URL}messages/${id}`);
  } catch {
    return NETWORK_ERROR;
  }
};
export const Subscribe = async (token) => {
  try {
    return await Axios.post(
      `${BASE_URL}payments/subscribe`,
      { token: token }
    );
  } catch {
    return NETWORK_ERROR;
  }
};

export const SubscribeAnnual = async (token) => {
  try {
    return await Axios.post(
      `${BASE_URL}payments/subscribe/annual`,
      { token: token }
    );
  } catch {
    return NETWORK_ERROR;
  }
};
export const addClassroom = async (formData) => {
  try {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return await Axios.post(`${BASE_URL}classrooms/add`, formData, config);
  } catch (err) {
    return err;
  }
};

export const addStudio = async (formData) => {
  try {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return await Axios.post(`${BASE_URL}studios/add`, formData, config);
  } catch (err) {
    return err;
  }
};
export const updateStudio = async (formData, id) => {
  try {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return await Axios.patch(
      `${BASE_URL}studios/update/${id}`,
      formData,
      config
    );
  } catch (err) {
    return err;
  }
};
export const updateClassroom = async (formData, id) => {
  try {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return await Axios.patch(
      `${BASE_URL}classrooms/update/${id}`,
      formData,
      config
    );
  } catch (er) {
    return er;
  }
};

export const addFitnessPlan = async (formData) => {
  try {
    return await Axios.post(
      `${BASE_URL}fitness-plan/add/`,
      formData);
  } catch (er) {
    return er;
  }
};

export const getAllPlans = async () => {
  try {

    return await Axios.get(`${BASE_URL}fitness-plan/`);
  } catch (er) {
    return er;
  }
};

export const reorderFitnessPlans = async (data) => {
  try {
    return await Axios.post(`${BASE_URL}fitness-plan/reorder`, data);
  } catch (er) {
    return er;
  }
};
export const reorderClassRooms = async (data) => {
  try {
    return await Axios.post(`${BASE_URL}classrooms/reorder`, data);
  } catch (er) {
    return er;
  }
};

export const reorderStudioService = async (data) => {
  try {
    return await Axios.post(`${BASE_URL}studios/reorder`, data);
  } catch (er) {
    return er;
  }
};

export const deleteplanByid = async (id) => {
  try {
    return await Axios.delete(`${BASE_URL}fitness-plan/${id}`);
  } catch (er) {
    return er;
  }
};

export const loadSinglePlan = async (id) => {
  try {
    return await Axios.get(`${BASE_URL}fitness-plan/${id}`);
  } catch (er) {
    return er;
  }
};

export const updatePlan = async (id, formdata) => {
  try {
    return await Axios.post(
      `${BASE_URL}fitness-plan/update/${id}`,
      formdata
    );
  } catch (er) {
    return er;
  }
};

export const addEntry = async (id, formdata) => {
  try {
    return await Axios.post(
      `${BASE_URL}fitness-plan/enteries/add/${id}`,
      formdata
    );
  } catch (er) {
    return er;
  }
};

export const deleteSingleEntry = async (pid, eid) => {
  try {
    return await Axios.delete(
      `${BASE_URL}fitness-plan/enteries/remove/${pid}/${eid}`
    );
  } catch (er) {
    return er;
  }
};

export const deleteSingleDay = async (pid, eid, did) => {
  try {
    return await Axios.delete(
      `${BASE_URL}fitness-plan/days/remove/${pid}/${eid}/${did}`
    );
  } catch (err) {
    return err;
  }
};

export const updateSingleEntery = async (pid, eid, formdata) => {
  try {
    return await Axios.post(
      `${BASE_URL}fitness-plan/entery/update/${pid}/${eid}`,
      formdata);
  } catch (err) {
    return err;
  }
};

export const updateSingleDay = async (pid, eid, did, formdata) => {
  try {
    return await Axios.post(
      `${BASE_URL}fitness-plan/day/update/${pid}/${eid}/${did}`,
      formdata
    );
  } catch (err) {
    return err;
  }
};

export const addDayInEntery = async (pid, eid, formData) => {
  try {
    return await Axios.post(
      `${BASE_URL}fitness-plan/enteries/days/add/${pid}/${eid}`,
      formData
    );
  } catch (err) {
    return err;
  }
};

export const contactUs = async (contactData) => {
  try {
    return await Axios.post(
      `${BASE_URL}users/contact-us`,
      contactData
    );
  } catch (err) {
    return err;
  }
};

export const sendEmail = async (email, subject, html) => {
  try {
    return await Axios.post(
      `${BASE_URL}payments/send-email`,
      { email, subject, html },
    );
  } catch (err) {
    return err;
  }
};

export const getUserDetail = async (body) => {
  try {
    return await Axios.post(
      `${BASE_URL}payments/subscription-status`,
      body,
    );
  } catch (err) {
    return err;
  }
};


export const userVerification = async (userId) => {
  try {
    return await Axios.get(`${BASE_URL}users/verify-user/${userId}`);
  } catch (err) {
    return err;
  }
};


export const updateUser = async (body) => {
  try {
    return await Axios.put(`${BASE_URL}users/update-user`, body);
  } catch (err) {
    return err;
  }
};

export const singleUser = async (email) => {
  try {
    return await Axios.post(`${BASE_URL}users/finduser`, { email });
  } catch (err) {
    return err;
  }
};

export const resetPassword = async (email, password) => {
  try {
    return await Axios.post(`${BASE_URL}users/reset-password`, { email, password });
  } catch (err) {
    return err;
  }
};

export const changePassword = async (email, password, oldPassword) => {
  try {
    return await Axios.post(`${BASE_URL}users/change-password`, {
      email,
      password,
      oldPassword,
    });
  } catch (err) {
    return err;
  }
};

export const updatePaymentinfo = async (id, cnum, month, year, customerId) => {
  try {
    return await Axios.post(
      `${BASE_URL}payments/update-payment-info`,
      {
        id,
        cnum,
        month,
        year,
        customerId
      },

    );
  } catch (err) {
    return err;
  }
};

export const updateUserinfo = async (email, subscription, card) => {
  try {
    return await Axios.post(
      `${BASE_URL}users/update-payment`,
      {
        email,
        subscription,
        card,
      },

    );
  } catch (err) {
    return err;
  }
};

export const loadAllUsers = async () => {
  try {
    return await Axios.get(`${BASE_URL}users/alluser`);
  } catch {
    return NETWORK_ERROR;
  }
};


export const searchUsers = async (searchText) => {
  try {
    return await Axios.get(`${BASE_URL}users/search?query=${encodeURIComponent(searchText)}`);
  } catch {
    return NETWORK_ERROR;
  }
};

export const sendOTP = async (email) => {
  try {
    return await Axios.post(`${BASE_URL}users/send-otp`, { email });
  } catch (error) {
    return error;
  }
};

export const verifyOTP = async (body) => {
  try {
    return await Axios.post(`${BASE_URL}users/verify-otp`, body,);
  } catch {
    return NETWORK_ERROR;
  }
};

export const reorderHealthAndFitness = async (data) => {
  try {
    return await Axios.post(`${BASE_URL}health-resource/reorder`, data,);
  } catch (er) {
    return er;
  }
};

export const addHealthAndFitness = async (data) => {
  try {
    return await Axios.post(`${BASE_URL}health-resource/create`, data,);
  } catch (err) {
    return err;
  }
};

export const getHealthAndFitnessService = async () => {
  try {
    return await Axios.get(`${BASE_URL}health-resource/`,);
  } catch {
    return NETWORK_ERROR;
  }
};

export const deleteHealthResource = async (id) => {
  try {
    return await Axios.delete(`${BASE_URL}health-resource/delete/${id}`,);
  } catch (error) {
    return error;
  }
};

export const loadSingleHealthResource = async (id) => {
  try {
    return await Axios.get(`${BASE_URL}health-resource/${id}`,);
  } catch {
    return NETWORK_ERROR;
  }
};

export const updateHealthResource = async (resourceData, id) => {
  try {
    return await Axios.post(`${BASE_URL}health-resource/update/${id}`, resourceData,);
  } catch {
    return NETWORK_ERROR;
  }
};

export const removeHealthResourceLike = async (id) => {
  try {
    return await Axios.delete(`${BASE_URL}health-resource/likes/remove/${id}`,);
  } catch {
    return NETWORK_ERROR;
  }
};

export const addHealthResourceLike = async (id) => {
  try {
    return await Axios.post(`${BASE_URL}health-resource/update/${id}`, {});
  } catch {
    return NETWORK_ERROR;
  }
};

export const loadAllHealthResourceFav = async (userId) => {
  try {
    return await Axios.get(
      `${BASE_URL}health/favorites/user`,

    );
  } catch {
    return NETWORK_ERROR;
  }
};

export const loadSingleHealthFav = async (resourceId) => {
  try {
    return await Axios.get(
      `${BASE_URL}health/favorites/${resourceId}/user`,

    );
  } catch {
    return NETWORK_ERROR;
  }
};

export const addHealthResourceFav = async (resourceId) => {
  try {
    return await Axios.post(
      `${BASE_URL}health/favorites/add/${resourceId}`, {},

    );
  } catch {
    return NETWORK_ERROR;
  }
};

export const removeHealthResourceFav = async (resourceId) => {
  try {
    return await Axios.delete(
      `${BASE_URL}health/favorites/remove/${resourceId}`,

    );
  } catch {
    return NETWORK_ERROR;
  }
};

export const getFaqService = async () => {
  try {
    return await Axios.get(`${BASE_URL}faq/category`,);
  } catch {
    return NETWORK_ERROR;
  }
};

export const getRecommendedEquipmentService = async () => {
  try {
    return await Axios.get(
      `${BASE_URL}recommended-equipments/`,

    );
  } catch {
    return NETWORK_ERROR;
  }
}

export const addFaqCategory = async (categoryData) => {
  try {
    return await Axios.post(`${BASE_URL}faq/create-category`, categoryData,);
  } catch {
    return NETWORK_ERROR;
  }
};

export const addEquipmentService = async (resourceData) => {
  try {
    return await Axios.post(
      `${BASE_URL}recommended-equipments/create/`, resourceData,

    );
  } catch {
    return NETWORK_ERROR;
  }
}

export const loadSingleFaqCategory = async (id) => {
  try {
    return await Axios.get(`${BASE_URL}faq/category/${id}`,);
  } catch (er) {
    return er;
  }
};

export const reorderEquipmentsService = async (data) => {
  try {
    return await Axios.post(`${BASE_URL}recommended-equipments/reorder`, data,);
  } catch (er) {
    return er;
  }
}

export const updateCategory = async (id, formdata) => {
  try {
    return await Axios.post(
      `${BASE_URL}faq/category/update/${id}`,
      formdata,

    );
  } catch (er) {
    return er;
  }
};

export const deleteFaqCategory = async (id) => {
  try {
    return await Axios.delete(`${BASE_URL}faq/category/delete/${id}`,);
  } catch (error) {
    return error;
  }
};

export const reorderFaq = async (data) => {
  try {
    return await Axios.post(`${BASE_URL}faq/reorder`, data,);
  } catch (er) {
    return er;
  }
};

export const reorderFaqQuestion = async (data, categoryId) => {
  try {
    return await Axios.post(`${BASE_URL}faq/question-reorder/${categoryId}`, data,);
  } catch (er) {
    return er;
  }
};

export const addFaqQuestion = async (categoryId, categoryData) => {
  try {
    return await Axios.post(`${BASE_URL}faq/create-question/${categoryId}`, categoryData,);
  } catch {
    return NETWORK_ERROR;
  }
};

export const loadSingleEquipment = async (id) => {
  try {
    return await Axios.get(`${BASE_URL}recommended-equipments/${id}`,);
  } catch {
    return NETWORK_ERROR;
  }
}

export const updateEquipments = async (resourceData, id) => {
  try {
    return await Axios.post(`${BASE_URL}recommended-equipments/update/${id}`, resourceData,);
  } catch {
    return NETWORK_ERROR;
  }
};

export const deleteEquipements = async (id) => {
  try {
    return await Axios.delete(`${BASE_URL}recommended-equipments/delete/${id}`,);
  } catch (error) {
    return error;
  }
};

export const removeEquipmentLike = async (id) => {
  try {
    return await Axios.delete(`${BASE_URL}recommended-equipments/likes/remove/${id}`,);
  } catch {
    return NETWORK_ERROR;
  }
};

export const loadSingleFaqQuestion = async (id) => {
  try {
    return await Axios.get(`${BASE_URL}faq/question/${id}`,);
  } catch (er) {
    return er;
  }
};

export const updateQuestion = async (id, formdata) => {
  try {
    return await Axios.post(
      `${BASE_URL}faq/question/update/${id}`,
      formdata,

    );
  } catch (er) {
    return er;
  }
};

export const deleteFaqQuestion = async (id) => {
  try {
    return await Axios.delete(`${BASE_URL}faq/question/delete/${id}`,);
  } catch (error) {
    return error;
  }
};
export const addEquipmentFav = async (resourceId) => {
  try {
    return await Axios.post(
      `${BASE_URL}equipments/favroites/add/${resourceId}`, {},

    );
  } catch {
    return NETWORK_ERROR;
  }
};

export const removeEquipmentFav = async (resourceId) => {
  try {
    return await Axios.delete(
      `${BASE_URL}equipments/favroites/remove/${resourceId}`,

    );
  } catch {
    return NETWORK_ERROR;
  }
};

export const loadSingleEquipmentFav = async (resourceId) => {
  try {
    return await Axios.get(
      `${BASE_URL}equipments/favroites/${resourceId}/user`,

    );
  } catch {
    return NETWORK_ERROR;
  }
};

export const loadAllEquipmentFav = async (userId) => {
  try {
    return await Axios.get(
      `${BASE_URL}equipments/favroites/user`,

    );
  } catch {
    return NETWORK_ERROR;
  }
};
export const deleteAccount = async () => {
  try {
    return await Axios.delete(
      `${BASE_URL}users/delete`,

    );
  } catch {
    return NETWORK_ERROR;
  }
};

export const loadAllCredits = async (page, size) => {
  try {
    const pageData = page ? `?page=${page}` : ``;
    const sizeData = size ? `&size=${size}` : ``;
    return await Axios.get(`${BASE_URL}credits${pageData}${sizeData}`);
  } catch (error) {
    return NETWORK_ERROR;
  }
}


export const addCredits = async (data) => {
  try {
    return await Axios.post(`${BASE_URL}credits/add`, data);
  } catch (error) {
    return NETWORK_ERROR;
  }
}


export const updateCredits = async (data, id) => {
  try {
    return await Axios.put(`${BASE_URL}credits/update/${id}`, data);
  } catch (error) {
    return NETWORK_ERROR;
  }
}

export const getCreditsById = async (id) => {
  try {
    const response = await Axios.get(`${BASE_URL}credits/getcredit/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};


export const deleteCredits = async (id) => {
  try {
    return await Axios.delete(`${BASE_URL}credits/delete/${id}`);
  } catch (error) {
    return NETWORK_ERROR;
  }
}
export const loadAllpushNotification = async (page, pageSize) => {
  try {
    const schedulePage = page ? `?schedulePage=${page}` : '';
    const scheduleSize = pageSize ? `&scheduleSize=${pageSize}` : '';


    return await Axios.get(`${BASE_URL}pushNotification?${schedulePage}${scheduleSize}`);
  } catch {
    return NETWORK_ERROR;
  }
};

export const getAllReportedComments = async () => {
  try {
    return await Axios.get(`${BASE_URL}users/get-all-reported-comments`);
  } catch {
    return NETWORK_ERROR;
  }
};

export const commentsAction = async (data) => {
  try {
    return await Axios.post(`${BASE_URL}users/admin-comment-action`, data);
  } catch {
    return NETWORK_ERROR;
  }
};

export const reportComment = async (classId, commentId) => {
  try {
    return await Axios.post(`${BASE_URL}users/report-comment/${classId}/${commentId}`);
  } catch {
    return NETWORK_ERROR;
  }
};


export const addPushNotication = async (formData) => {
  try {
    return await Axios.post(`${BASE_URL}pushNotification/add`, formData);
  } catch (err) {
    return err;
  }
};


export const updatePushNotication = async (formData, id) => {
  try {
    return await Axios.put(`${BASE_URL}pushNotification/update/${id}`, formData);
  } catch (err) {
    return err;
  }
};


export const deletePushNotication = async (id) => {
  try {
    return await Axios.delete(
      `${BASE_URL}pushNotification/delete/${id}`,


    );
  } catch {
    return NETWORK_ERROR;
  }
};

