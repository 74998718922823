import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from 'react-router-dom';
import "../../assets/css/mydata.css";
import { Collapse, Spin } from 'antd';
import { Col, Divider, Row } from 'antd';
import pushupIcon from "../../assets/Images/pushup.svg";
import treadmillIcon from "../../assets/Images/treadmill.svg";
import squatIcon from "../../assets/Images/squat.svg";
import dumbellIcon from "../../assets/Images/dumbell.svg";

import editIcon from "../../assets/Images/edit.svg";

import ageIcon from "../../assets/Images/age.svg";
import genderIcon from "../../assets/Images/sex.svg";
import heightIcon from "../../assets/Images/ruler.svg";
import weightIcon from "../../assets/Images/weight.svg";
import infiniteIcon from "../../assets/Images/goal-weight.svg";
import bikeIcon from "../../assets/Images/cycle.svg";
import heartIcon from "../../assets/Images/heart.svg";
import birthdayIcon from "../../assets/Images/birthday.svg";

import { getmyData } from '../../dataServices/Services';

function MyData() {

  const { state } = useLocation();
  const { userId, email, userName } = state || {};
  const [userData, setuserData] = useState(null);
  const [BMIid, setBMIId] = useState(null);
  const [experienceLevelId, setExperienceLevelId] = useState(null);
  const [healthAndFitnessId, setHealthAndFitnessId] = useState(null);
  const history = useHistory();
  const { Panel } = Collapse;
  const onChange = (key) => {
    console.log(key);
  };


  useEffect(() => {
    if (userId) {
      const fetchData = async () => {
        try {
          const mydata = await getmyData(userId);
          setuserData(mydata.data)
          setBMIId(mydata.data?.BMI_category?.id);
          setExperienceLevelId(mydata.data?.experienceLevel?.id);
          setHealthAndFitnessId(mydata.data.healthAndFitnessId);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };


      fetchData();
    }

  }, [userId]);





  const handleRecommendations = (id) => {
    console.log({ userData })
    history.push({
      pathname: '/recommendation',
      state: { id, selectedGoals: userData.selectedGoals },
    });
  };

  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;



  return (

    <>
      {userData ? (
        <div className="mydata-page">
          <h1 tabIndex="0">My Data</h1>

          <section className="mydata-profile">
            {/* <ProfilePic
          image={DemoPic}
          roundedColor="#321124"
          imageWidth="150"
          imageHeight="150"
          roundedSize="13"
        /> */}
            <div className="mydata-profile-info">
              <h4 tabIndex="0">{userName}</h4>
              <email tabIndex="0">{email}</email>
            </div>
          </section>

          <section className="mydata-stats d-flex">
            <div className="flex-fill">
              <h4 tabIndex="0" className="mydata-stats-title">Edit</h4>
              <p tabIndex="0">
                This section contains some personal information so we can better help
                you with your fitness journey. Know that this information is only ever
                seen by you.
              </p>
            </div>
            <button tabIndex="0" type="button" className="btn btn-primary" aria-label="my data edit button" onClick={() => history.push({
              pathname: '/updateMydata',
              state: { userId: userId }
            })}>
              <img className="white" src={editIcon} alt=""></img>
              My Data
            </button>
          </section>


          <section className="mydata-carousel-wrap">

            {/* <button className="mydata-carousel-btn mydata-carousel-btn-edit" onClick={() => history.push({
                                                              pathname: '/updateMydata',
                                                              state: { userId: userId } 
                                                              })}>
          <img src={editIcon} alt=""></img>
        </button> */}

            {/* <button className="mydata-carousel-btn">&lsaquo;</button> */}

            <div className="mydata-carousel w-100">
              <div tabIndex="0" className="mydata-carousel-card">
                <div className="card-inner">
                  <p className="mydata-carousel-card-title">Age</p>
                  <img className="mydata-carousel-card-img" src={ageIcon} alt="" />
                  {userData?.age ? (
                    <>
                      <div className="mydata-carousel-card-value">{userData?.age}</div>
                    </>
                  ) : (<>
                    <p className="stats-card-value"><span>Not Enough Data</span></p>
                  </>)}
                </div>
              </div>

              <div tabIndex="0" className="mydata-carousel-card">
                <div className="card-inner">
                  <p className="mydata-carousel-card-title">Sex</p>
                  <img className="mydata-carousel-card-img" src={genderIcon} alt="" />
                  {userData?.sex ? (
                    <div className="mydata-carousel-card-value">{userData?.sex}</div>
                  ) : (<>
                    <p className="stats-card-value"><span>Not Enough Data</span></p>
                  </>)}
                </div>
              </div>

              <div tabIndex="0" className="mydata-carousel-card">
                <div className="card-inner">
                  <p className="mydata-carousel-card-title">Height</p>
                  <img className="mydata-carousel-card-img" src={heightIcon} alt="" />
                  {userData?.height ? (
                    <div className="mydata-carousel-card-value">{userData?.height}<span>{" "}inch</span></div>
                  ) : (<>
                    <p className="stats-card-value"><span>Not Enough Data</span></p>
                  </>)}
                </div>
              </div>

              <div tabIndex="0" className="mydata-carousel-card">
                <div className="card-inner">
                  <p className="mydata-carousel-card-title">Current Weight</p>
                  <img className="mydata-carousel-card-img" src={weightIcon} alt="" />
                  {userData?.currentWeight ? (<>
                    <div className="mydata-carousel-card-value">{userData?.currentWeight} <span>lbs</span></div>
                  </>) : (<>
                    <p className="stats-card-value"><span>Not Enough Data</span></p>
                  </>)}
                </div>
              </div>

              <div tabIndex="0" className="mydata-carousel-card">
                <div className="card-inner">
                  <p className="mydata-carousel-card-title">Goal Weight</p>
                  <img
                    className="mydata-carousel-card-img"
                    src={infiniteIcon}
                    alt=""
                  />
                  {userData?.goalWeight ? (
                    <>
                      <div className="mydata-carousel-card-value">{userData?.goalWeight} <span>lbs</span></div>
                    </>
                  ) : (<>
                    <p className="stats-card-value"><span>0</span></p>
                  </>)}
                </div>
              </div>

              <div tabIndex="0" className="mydata-carousel-card">
                <div className="card-inner">
                  <p className="mydata-carousel-card-title" aria-label="Average Activity Level">Avg Activity Level</p>
                  <img className="mydata-carousel-card-img" src={bikeIcon} alt="" />
                  {userData?.averageActivityLevel ? (<>
                    <div className="mydata-carousel-card-value">{userData?.averageActivityLevel}</div>
                  </>
                  ) : (<>
                    <p className="stats-card-value"><span>Not Enough Data</span></p>
                  </>)}
                </div>
              </div>

              <div tabIndex="0" className="mydata-carousel-card">
                <div className="card-inner">
                  <p className="mydata-carousel-card-title">Experience Level</p>
                  <img className="mydata-carousel-card-img" src={heartIcon} alt="" />
                  {userData?.experienceLevel?.experienceLevel ? (
                    <div className="mydata-carousel-card-value">{userData?.experienceLevel?.experienceLevel}</div>
                  ) : (
                    <>
                      <p className="stats-card-value"><span>Not Enough Data</span></p>
                    </>
                  )}
                </div>


              </div>
              {/* <div tabIndex="0" className="mydata-carousel-card">
            <div className="card-inner">
            <p className="mydata-carousel-card-title">Birthday</p>
            <img
              className="mydata-carousel-card-img"
              src={birthdayIcon}
              alt=""
            />
            <div className="mydata-carousel-card-value">{userData?.dateOfBirth ? userData.dateOfBirth.split('T')[0] : ''}</div>
          </div>
          </div> */}
              {/* blank-div-area-start */}
              {/* <div tabIndex="0" className="mydata-carousel-card my-data-carosel-blank"></div>
          <div tabIndex="0" className="mydata-carousel-card my-data-carosel-blank"></div>
          <div tabIndex="0" className="mydata-carousel-card my-data-carosel-blank"></div>
          <div tabIndex="0" className="mydata-carousel-card my-data-carosel-blank"></div>
          <div tabIndex="0" className="mydata-carousel-card my-data-carosel-blank"></div>
          <div tabIndex="0" className="mydata-carousel-card my-data-carosel-blank"></div>
          <div tabIndex="0" className="mydata-carousel-card my-data-carosel-blank"></div>
          <div tabIndex="0" className="mydata-carousel-card my-data-carosel-blank"></div> */}
              {/* blank-div-area-end */}
              {/* <button className="mydata-carousel-btn">&rsaquo;</button> */}
            </div>
          </section>

          {/* <div className="" >
     <Collapse defaultActiveKey={['1']} ghost items={items} />
    </div> */}


          <Collapse tabIndex="0" className="custom-collapse" defaultActiveKey={['1']} onChange={() => { }}>
            <Panel header="My Selected Goals" key="1">
              <Row gutter={16}>
                {userData?.selectedGoals && userData?.selectedGoals.length ? (userData?.selectedGoals.map((goalObj, index) => (
                  <Col tabIndex="0" className="gutter-row" span={24} key={index}>
                    {goalObj.goalName && <p>{goalObj.goalName}</p>}
                  </Col>
                ))) : (<>
                  <p className="stats-card-value"><span>Not Enough Data</span></p>
                </>)}
              </Row>
            </Panel>
          </Collapse>

          <section className="mydata-stats">
            <h4 tabIndex="0" className="mydata-stats-title">My Fitness Tests</h4>
            <p tabIndex="0" >
              <p tabIndex="0">
                Based on the data you have provided, here is some information that
                may be useful to you on your fitness journey. Remember that these
                are just data points to give you a snapshot of where you might be in
                your health and fitness journey, and that you have the power to make
                change in your life if you so choose, you are worth it.
              </p>
            </p>
          </section>

          <section className="stats-card-wrap grid-4">
            <div tabIndex="0" className="stats-card">
              <p className="stats-card-title">1 Minute Push-up Fitness Test (assesses general upper body strength)</p>
              <img className="stats-card-img" src={pushupIcon} alt="" />
              {userData?.pushUpTest ? (
                <p className="stats-card-value">{userData?.pushUpTest}<span>reps</span></p>
              ) : (<>
                <p className="stats-card-value"><span>Not Enough Data</span></p>
              </>)}
            </div>
            <div tabIndex="0" className="stats-card">
              <p className="stats-card-title">1 Minute Curl-Up Fitness Test (assesses general abdominal strength)</p>
              <img className="stats-card-img" src={dumbellIcon} alt="" />
              {userData?.curlUpTest ? (
                <p className="stats-card-value">{userData?.curlUpTest}<span>reps</span></p>
              ) : (<>
                <p className="stats-card-value"><span>Not Enough Data</span></p>
              </>)}
            </div>
            <div tabIndex="0" className="stats-card">
              <p className="stats-card-title">1 Minute Squat Fitness Test (assesses general lower body strength)</p>
              <img className="stats-card-img" src={squatIcon} alt="" />
              {userData?.squatTest ? (
                <p className="stats-card-value">{userData?.squatTest}<span>reps</span></p>
              ) : (<>
                <p className="stats-card-value"><span>Not Enough Data</span></p>
              </>)}
            </div>
            <div tabIndex="0" className="stats-card">
              <p className="stats-card-title">3 Minute YMCA Cardio Fitness Test (assesses general endurance and efficiency of cardiovascular system)</p>
              <img className="stats-card-img" src={treadmillIcon} alt="" />
              {userData?.cardioTest ? (
                <p className="stats-card-value">{userData?.cardioTest}<span>bpm</span></p>
              ) : (<>
                <p className="stats-card-value"><span>Not Enough Data</span></p>
              </>)}
            </div>
          </section>

          {(BMIid || experienceLevelId || userData?.selectedGoals.length) ? (
            <>
              <section className="mydata-stats">
                <h4 tabIndex="0" className="mydata-stats-title">My Recommendations</h4>
                <p tabIndex="0">
                  Here are a few notes for you to help you figure out some next steps
                  based on the information you provided.
                </p>
              </section>
              {BMIid && (
                <button tabIndex="0" type="button" className="btn btn-primary btn-block" onClick={() => handleRecommendations(BMIid)}>
                  <p>BMI Category Recomendations</p>
                  <span>&rsaquo;</span>
                </button>
              )}

              {experienceLevelId && (
                <button tabIndex="0" type="button" className="btn btn-primary btn-block" onClick={() => handleRecommendations(experienceLevelId)}>
                  <p>Experience Level Recomendations</p>
                  <span>&rsaquo;</span>
                </button>

              )}
              {(userData && userData.selectedGoals.length > 0) && (
                <button tabIndex="0" type="button" className="btn btn-primary btn-block" onClick={() => handleRecommendations(8)}>
                  <p>Health and Fitness Goal Recomendations</p>
                  <span>&rsaquo;</span>
                </button>
              )}
            </>
          ) : null}
        </div>
      ) : (
        <div className="loader">
          <Spin />
        </div>
      )
      }
    </>

  );
}

export default MyData;
