/* eslint-disable react-hooks/exhaustive-deps */
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons"
import React, { useEffect, useRef, useState } from 'react'
import FilterResults from 'react-filter-search'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import '../../assets/css/classrooms.css'
import { deleteHealthResource, getHealthAndFitnessService, reorderHealthAndFitness } from '../../dataServices/Services'
import { getHealthAndFitness } from '../../redux/actions/healthAndFitnessActions'

function HealthAndFitness({ getResource, resource, auth }) {

    const history = useHistory()
    const [ searchValue, setsearchValue ] = useState('')
    const [ searchData, setSearchData ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ resources, setResources ] = useState([])
    const [ categories, setCategories ] = useState([])
    const { addToast } = useToasts()
    const toastRef = useRef();


    const fetchHealthResource = async () => {
        setLoading(true)
        let token = localStorage.getItem('token')
        if (token !== null) {
            let r = await getHealthAndFitnessService()

            if (r.data && r.data.healthResources) {
                getResource(r.data.healthResources)
                setResources(r.data.healthResources)
                setLoading(false)
            }
        } else {
            setLoading(false)
            addToast('Resources not loaded', { appearance: 'error' })
        }
    }

    const deleteResource = async (resourceId, index) => {
        try {
            const response = await deleteHealthResource(resourceId)
            alert(response.data.success)
            fetchHealthResource()
        } catch (error) {
            addToast(<span ref={toastRef}
                aria-label={error.message}
                tabIndex={0}>{error.message}</span>, {
                appearance: 'error',
                autoDismiss: true,
            })
            toastRef.current.focus()
        }
    }

    const getCategory = () => {
        let cat = []
        for (const element of resources) {
            if (resources.length > 0) {
                if (cat.indexOf(element.category.toLowerCase()) === -1) {
                    cat.push(element.category.toLowerCase())
                }
            }
        }

        setCategories(cat)
    }
    useEffect(() => {
        fetchHealthResource()
    }, [])

    useEffect(() => {
        getCategory()
    }, [ resources ])

    const reorderHealthResource = async (currentOrder, newOrder) => {
        const data = {
            currentOrder: resource.healthResource[ currentOrder ].order,
            newOrder: resource.healthResource[ newOrder ].order
        }
        await reorderHealthAndFitness(data).then((data) => {
            fetchHealthResource()
        })
    }

    const handleClearSearch = () => {
    setsearchValue('');
    setSearchData([]);
  };


    return (
        <div>
            {' '}
            <div className="top-bar">
                {' '}
                <div className="top-bar-search">
                    {' '}
                    <div className="custom-input mr-3">
                    <input
                        type="text"
                        name=""
                        id=""
                        value={searchValue}
                        placeholder="Search"
                        aria-label="Search Health resource name"
                        onChange={(e) => {
                            setsearchValue(e.target.value)
                            setSearchData(resource.healthResource)
                        }}
                    />
                    <button onClick={handleClearSearch} aria-label="Clear search data">
					<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
<path d="M24.2037 3.88328C23.7481 3.42766 23.0094 3.42766 22.5538 3.88328L14.0437 12.3934L5.53362 3.88328C5.07801 3.42766 4.33931 3.42766 3.88371 3.88328C3.42809 4.33889 3.42809 5.07758 3.88371 5.53319L12.3938 14.0433L3.88373 22.5533C3.42811 23.009 3.42811 23.7476 3.88373 24.2033C4.33934 24.6589 5.07803 24.6589 5.53364 24.2033L14.0437 15.6932L22.5538 24.2033C23.0094 24.6589 23.7481 24.6589 24.2037 24.2033C24.6593 23.7476 24.6593 23.009 24.2037 22.5534L15.6936 14.0433L24.2037 5.53319C24.6593 5.07758 24.6593 4.33889 24.2037 3.88328Z"/>
</svg>
					</button>
                    </div>{' '}
                    <select className="dropdown-new"
                        name=""
                        id=""
                        aria-label="Search health resource by category "
                        value={searchValue}
                        onChange={(e) => {
                            setsearchValue(e.target.value)
                            setSearchData(resource.healthResource)
                        }}
                        
                    >
                        {categories &&
                            categories.map((cat) => <option value={cat}>{cat}</option>)}
                    </select>
                    {searchData && (
                        <FilterResults
                            value={searchValue}
                            data={searchData}
                            renderResults={(results) => (
                                <div className="w-100">
                                    {results.map((el) => (
                                        <div className="select-custom"
                                            onClick={(e) => {
                                                history.push(`/health-resource/${el._id}`)
                                                setSearchData([])
                                            }}
                                            onKeyPress={(e) => {
                                                history.push(`/health-resource/${el._id}`)
                                                setSearchData([])
                                            }}
                                        >
                                            <div>
                                                <p className="mb-0"
                                                    tabIndex={0}
                                                   
                                                >
                                                    {el.title} : Category {el.category}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        />
                    )}
                </div>
            </div>
            <div className="m-4 p-4">
                <h1 tabIndex={0} style={{ display: 'block' }}>
                    Welcome into Health and fitness guides
                </h1>{' '}
                <p tabIndex={0}>
                    Here you can read supplemental information to help you learn and grow in your health and fitness.
                </p>
                {auth.user.isTrainer && (
                    <button
                        onClick={(e) => {
                            history.push('/add-health-and-fitness')
                        }}
                        className="btn btn-primary"
                    >
                        Add New Health and Fitness Guide
                    </button>
                )}
                <h2 tabIndex={0}>Health And Fitness Guides</h2>
                {resource.healthResource.length ? (
                    <div className="p-4 m-2">
                        <div
                            aria-label="List of Health and Fitness Guides"
                            className="list-group"
                        >
                            {loading && (
                                <div
                                    class="spinner-border text-primary ml-auto mr-auto"
                                    role="status"
                                >
                                    <span class="sr-only">Loading...</span>
                                </div>
                            )}
                            {!loading &&
                                resource &&
                                !auth.user.isTrainer &&
                                resource.healthResource.map((c) => {
                                    return (
                                        <button
                                            key={c._id}
                                            onClick={() => {
                                                history.push(`/health-resource/${c._id}`)
                                            }}
                                            className="btn btn-primary m-2"
                                            style={{
                                                marginTop: '5px',
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                            }}
                                            tabIndex={0}
                                        >
                                            {c.title}
                                        </button>
                                    )
                                })}
                            {resource &&
                                auth.user.isTrainer &&
                                resource.healthResource.map((c, index) => {
                                    return (
                                        <div

                                            className="text-align-center"
                                            style={{ display: 'flex' }}
                                        >
                                            {' '}
                                            <button
                                                key={c._id}
                                                id={`newFavorite-${index}`}
                                                onClick={() => {
                                                    history.push(`/health-resource/${c._id}`)
                                                }}
                                                className="btn btn-primary m-2"
                                                aria-label={`${c.title}-position ${index + 1}`}
                                                style={{
                                                    marginTop: '5px',
                                                    width: '90%',
                                                }}
                                                tabIndex={0}
                                            >
                                                {c.title}
                                            </button>
                                            {auth.user.isTrainer && (
                                                <>
                                                    {index !== 0 && (
                                                        <button
                                                            aria-label="Move Up The Health and fitness Guide"
                                                            className="btn btn-danger m-2"
                                                            onClick={() => reorderHealthResource(index, index - 1)}
                                                        >
                                                            <ArrowUpOutlined />
                                                        </button>
                                                    )}
                                                    {index !== (resource.healthResource.length - 1) && (
                                                        <button
                                                            aria-label="Move Down The Health and fitness guide"
                                                            className="btn btn-danger m-2"
                                                            onClick={() => reorderHealthResource(index, index + 1)}
                                                        >
                                                            <ArrowDownOutlined />
                                                        </button>
                                                    )}
                                                    <button
                                                        aria-label="delete Health and Fitness guide"
                                                        className="btn btn-danger m-2"
                                                        onClick={() => deleteResource(c._id, index)}
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </button>
                                                </>
                                            )}
                                            {auth.user.isTrainer && (
                                                <button
                                                    aria-label="Edit Health and Fitness guide"
                                                    className="btn btn-danger m-2"
                                                    onClick={(e) => {
                                                        history.push(`/editHealthResource/${c._id}`)
                                                    }}
                                                >
                                                    <i className="fa fa-pencil"></i>
                                                </button>
                                            )}
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                ) : ""}

            </div>
        </div>
    )
}
const mapDispatchToProps = (dispatch) => {
    return {
        getResource: (healthResource) => {
            dispatch(getHealthAndFitness(healthResource))
        },
    }
}
const mapStateToProps = (state) => ({
    resource: state.healthResource,
    auth: state.auth,
})
export default connect(mapStateToProps, mapDispatchToProps)(HealthAndFitness)
