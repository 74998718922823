/* eslint-disable react-hooks/exhaustive-deps */
import Axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { BASE_URL, NETWORK_ERROR } from '../../constants/Constants'
import {
    addEquipmentFav,
    getRecommendedEquipmentService,
    loadSingleEquipment,
    loadSingleEquipmentFav,
    removeEquipmentFav,
    removeEquipmentLike,
    reportComment
} from '../../dataServices/Services'
import isEmpty from '../../validation/is-empty'
import TextFieldGroup from '../common/TextFieldGroup'

import { getRecommendedEquipments } from '../../redux/actions/EquipmentsActions'
import SingleComment from './SingleComment'

function SingleEquipment({ auth, getEquipments }) {
    let token = localStorage.getItem('token')
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    }
    const { addToast } = useToasts()
    const toastRef = useRef()
    const [fav, setFav] = useState({})
    const [showComments, setShowComments] = useState(false)
    const [currentUserLike, setCurrentUserLike] = useState(false)
    const [resource, setResource] = useState({})
    const [comment, setCommentText] = useState('')
    const [, setLike] = useState('')
    const { id } = useParams()
    const [showFull, setShowFull] = useState(false)

    let description = resource.description
    const firstPart = description && description.slice(0, 180)
    const lastPart = description && description.slice(180, description.length)

    const deleteComment = async (classId, commentId) => {
        try {
            const res = await Axios({
                method: 'delete',
                url: `${BASE_URL}recommended-equipments/comments/remove/${classId}/${commentId}`,
                headers: { Authorization: `Bearer ${token}` },
            })
            addToast(<span ref={toastRef}
                aria-label={res.data.message}
                tabIndex={0}>{res.data.message}</span>, {
                appearance: 'success',
                autoDismiss: true,
            })
            toastRef.current.focus()
            let r = await getRecommendedEquipmentService()
            getEquipments(r.data.equipmentsData)
            fetchEquipment()

        } catch (error) {
            addToast(<span ref={toastRef}
                aria-label={error.message}
                tabIndex={0}>{error.message}</span>, {
                appearance: 'error',
                autoDismiss: true,
            })
            toastRef.current.focus()
        }
    }

    const addComment = async (comment) => {
        try {
            if (comment.trim() !== '') {
                await Axios.post(
                    `${BASE_URL}recommended-equipments/comments/add/${resource._id}`,
                    {
                        text: comment,
                    },
                    config
                )
                addToast(<span ref={toastRef}
                    aria-label={"Successfully added comment"}
                    tabIndex={0}>Successfully added comment</span>, {
                    appearance: 'success',
                    autoDismiss: true,
                })
                toastRef.current.focus()
                let r = await getRecommendedEquipmentService()
                getEquipments(r.data.equipmentsData)
                fetchEquipment()
                setCommentText("")
            }
        } catch (error) {
            console.log({ error })
        }
    }

    const fetchEquipment = async () => {
        let response = await loadSingleEquipment(id)
        setCurrentUserLike(
            Boolean(
                response.data.resourceData.likes.find(function (l) {
                    return l.userId === auth.user._id
                })
            )
        )
        setLike(
            response.data.resourceData.likes.filter(function (l) {
                return l.userId === auth.user._id
            })[0]
        )

        if (response.status === 200) {
            setResource(response.data.resourceData)
        }
    }
    const fetchEquipmentFav = async () => {
        let r = await loadSingleEquipmentFav(id)

        if (r !== NETWORK_ERROR) {
            if (r.data.newFavorite) {
                setFav(r.data.newFavorite)
            } else {
                setFav({})
            }
        }
    }

    const addToFavroite = async (e) => {
        try {
            e.preventDefault()
            await addEquipmentFav(id)
            addToast(<span ref={toastRef}
                aria-label={"Successfully added to favorites"}
                tabIndex={0}>Successfully added to favorites</span>, {
                appearance: 'success',
                autoDismiss: true,
            })
            toastRef.current.focus()
            fetchEquipmentFav()
        } catch (error) {
            addToast(<span ref={toastRef}
                aria-label={"Unable to add to favroites"}
                tabIndex={0}>Unable to add to favroites</span>, {
                appearance: 'success',
                autoDismiss: true,
            })
            toastRef.current.focus()
        }
    }
    const removeFromFavorite = async (e) => {
        try {
            e.preventDefault()
            await removeEquipmentFav(fav._id)
            addToast(<span ref={toastRef}
                aria-label={"Successfully removed from  favroites"}
                tabIndex={0}>Successfully removed from  favroites</span>, {
                appearance: 'success',
                autoDismiss: true,
            })
            toastRef.current.focus()
            fetchEquipmentFav()
        } catch (error) {
            addToast(<span ref={toastRef}
                aria-label={"Unable to remove from favorites"}
                tabIndex={0}>Unable to remove from favorites</span>, {
                appearance: 'error',
                autoDismiss: true,
            })
            toastRef.current.focus()
        }
    }

    const handleReportComment = async (classId, commentId) => {
        try {
            await reportComment(classId, commentId)
            let r = await getRecommendedEquipmentService()
            getEquipments(r.data.equipmentsData)
            fetchEquipment()
            alert('Comment reported ')

        } catch {
            alert('Comment already reported ')
        }
    }

    useEffect(() => {
        fetchEquipment()
        fetchEquipmentFav()
    }, [])
    return (
        <div>
            {resource ? (
                <div className="d-flex justify-content-center m-2">
                    {' '}
                    <div className="card" style={{ width: '100%' }}>
                        <div className="card-body">
                            <h1
                                tabIndex={0}
                                style={{ display: 'block' }}
                                aria-label="Resources"
                            >
                                Welcome to Recommended Products Section
                            </h1>{' '}
                            <h5 className="card-title" tabIndex={0} aria-label="">
                                {resource.title}
                            </h5>
                            <p
                                className="card-text"
                                tabIndex={0}
                                aria-label={showFull ? firstPart + lastPart : firstPart}
                            >
                                {firstPart}
                                {!showFull && <span>...</span>}
                                {showFull && lastPart}
                                <button
                                    className="btn btn-link"
                                    type="button"
                                    aria-label={
                                        !showFull ? 'Read More Button' : 'Read less Button'
                                    }
                                    onClick={() => setShowFull(!showFull)}
                                >
                                    {showFull ? 'Read less' : 'Read More'}
                                </button>{' '}
                            </p>
                            <span
                                tabIndex={0}
                                className="btn-link"
                                aria-label='Click here to redirect to product page'
                                style={{
                                    cursor: "pointer",
                                }}
                                onClick={(e) => {
                                    window.location.href = resource.url;
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        window.location.href = resource.url
                                    }
                                }}
                            >
                                Click here to redirect to product page
                            </span>
                            <div
                                style={{
                                    marginTop: "20px",
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {' '}
                                {isEmpty(fav) ? (
                                    <button
                                        className="btn btn-danger m-1"
                                        aria-label="Add in favorites"
                                        onClick={addToFavroite}
                                    >
                                        <i className="fa fa-heart"></i>
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-danger m-1"
                                        aria-label="Remove from favorites"
                                        onClick={removeFromFavorite}
                                    >
                                        <i className="fa fa-heart-o"></i>
                                    </button>
                                )}
                                {currentUserLike ? (
                                    <button
                                        className="btn btn-primary "
                                        aria-label="Dis Like product"
                                        onClick={async (e) => {
                                            await removeEquipmentLike(resource._id)
                                            let r = await getRecommendedEquipmentService()
                                            getEquipments(r.data.equipmentsData)
                                            fetchEquipment()
                                        }}
                                    >
                                        {' '}
                                        <i className="fa fa-thumbs-down"></i>{' '}
                                        <span className="m-1 ml-3">
                                            {resource.likes && resource.likes.length}
                                        </span>
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-primary "
                                        aria-label="Like product"
                                        onClick={async () => {
                                            await Axios.post(
                                                `${BASE_URL}recommended-equipments/likes/add/${resource._id}`,
                                                { userId: auth.user._id },
                                                config
                                            )

                                            let r = await getRecommendedEquipmentService()
                                            getEquipments(r.data.equipmentsData)
                                            fetchEquipment()
                                        }}
                                    >
                                        {' '}
                                        <i className="fa fa-thumbs-up"></i>{' '}
                                        <span className="m-1 ml-3">
                                            {resource.likes && resource.likes.length}
                                        </span>
                                    </button>
                                )}{' '}
                                <button
                                    className="btn btn-primary m-1"
                                    aria-label="Show Comments"
                                    onClick={() => {
                                        setShowComments(!showComments)
                                    }}
                                >
                                    <i className="fa fa-comment"></i>
                                    <span className="m-1 ml-3">
                                        {resource.comments && resource.comments.length}
                                    </span>
                                </button>
                            </div>
                            {showComments && (
                                <div>
                                    {resource.comments && resource.comments.length > 0 ? (
                                        <div className=" border m-2 p-3">
                                            <div aria-label="Add new Comment Section">
                                                {' '}
                                                <TextFieldGroup
                                                    name="comment"
                                                    type="text"
                                                    value={comment}
                                                    onChange={(e) => {
                                                        setCommentText(e.target.value)
                                                    }}
                                                    placeholder="Add Comment"
                                                />
                                                <br></br>
                                                <button
                                                    className="btn btn-secondary"
                                                    aria-label="Add New Comment"
                                                    onClick={() => addComment(comment)}
                                                >
                                                    Add Comment
                                                </button>
                                            </div>

                                            <hr></hr>
                                            <h5>Comments</h5>
                                            {resource.comments.map((c) => {
                                                return (
                                                    <SingleComment
                                                        name={c.firstName + ' ' + c.lastName}
                                                        text={c.text}
                                                        time={Date.parse(c.createdAt)}
                                                        userId={auth.user._id}
                                                        classId={resource._id}
                                                        commentId={c._id}
                                                        commentUserId={c.userId}
                                                        delete={deleteComment}
                                                        isCommentAlreadyReported={!c?.reportedBy?.includes(auth.user._id)}
                                                        reportComment={handleReportComment}

                                                    />
                                                )
                                            })}
                                        </div>
                                    ) : (
                                        <div className="border m-2 p-3">
                                            {' '}
                                            <TextFieldGroup
                                                name="comment"
                                                type="text"
                                                value={comment}
                                                onChange={(e) => {
                                                    setCommentText(e.target.value)
                                                }}
                                                placeholder="Add Comment"
                                            />
                                            <br></br>
                                            <button
                                                className="btn btn-secondary"
                                                aria-label="Add New Comment"
                                                onClick={() => addComment(comment)}
                                            >
                                                Add Comment
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <h1>Not Found</h1>
            )
            }
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getEquipments: (equipments) => {
            dispatch(getRecommendedEquipments(equipments))
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SingleEquipment)
