/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { loadAllMessagesService } from '../../dataServices/Services'
import { useToasts } from 'react-toast-notifications'
import { connect } from 'react-redux'
import { loadMessageAction } from '../../redux/actions/messageActions'

import SingleMessage from './SingleMessage'
function MessageBoard({ loadMessageAction, auth }) {

	const [ allmessages, setAllMessages ] = useState([])
	const [ isLoading, setIsLoading ] = useState(false)
	const [ searchValue, setsearchValue ] = useState('')
	const [ searchData, setSearchData ] = useState([])

	const { addToast } = useToasts()
	const fetchmessages = async () => {
		setIsLoading(true)
		let r = await loadAllMessagesService()
		//console.log(r)
		if (r.status === 200) {
			// addToast('Messages Loaded Succesfully', {
			// 	appearance: 'success',
			// 	autoDismiss: true,
			// })
			loadMessageAction(r.data.messages)
			setAllMessages(r.data.messages)
			setIsLoading(false)
		}
	}

	useEffect(() => {
		fetchmessages()
	}, []);

	const handleSearchChange = (e) => {
    setsearchValue(e.target.value);
    // You may want to handle searching/filtering logic here
  };
	const handleClearSearch = () => {
    setsearchValue('');
	setSearchData([]);
  };
	
	return (
		<div>
			<div className="top-bar">
				{' '}
				<div className="top-bar-search">
					{' '}
					<div className="custom-input">
					<input
						value={searchValue}
						type="text"
						name=""
						id=""
						placeholder="Search"
						aria-label="Search classroom by user id or user name"
						onChange={handleSearchChange}
					/>
					<button onClick={handleClearSearch} aria-label="Clear search data">
					<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
<path d="M24.2037 3.88328C23.7481 3.42766 23.0094 3.42766 22.5538 3.88328L14.0437 12.3934L5.53362 3.88328C5.07801 3.42766 4.33931 3.42766 3.88371 3.88328C3.42809 4.33889 3.42809 5.07758 3.88371 5.53319L12.3938 14.0433L3.88373 22.5533C3.42811 23.009 3.42811 23.7476 3.88373 24.2033C4.33934 24.6589 5.07803 24.6589 5.53364 24.2033L14.0437 15.6932L22.5538 24.2033C23.0094 24.6589 23.7481 24.6589 24.2037 24.2033C24.6593 23.7476 24.6593 23.009 24.2037 22.5534L15.6936 14.0433L24.2037 5.53319C24.6593 5.07758 24.6593 4.33889 24.2037 3.88328Z"/>
</svg>
					</button>
					</div>
				</div>
			</div>
			<div className="m-4 p-4">
				<h1 tabIndex={0} style={{ display: 'block' }} aria-label="Classrooms">
					Welcome to Message Dashboard
				</h1>{' '}
				<p tabIndex={0}>Here you can Read all the messages from Users</p>
				{isLoading ? (
					<div
						class="spinner-border text-primary ml-auto mr-auto"
						role="status"
					>
						<span class="sr-only">Loading...</span>
					</div>
				) : allmessages.map((m, ind) => {
					return <SingleMessage singleMessage={m} fetchAll={fetchmessages} />
				})}
			</div>
		</div>
	)
}
const mapDispatchtoProps = (dispatch) => {
	return {
		loadMessageAction: (messages) => {
			dispatch(loadMessageAction(messages))
		},
	}
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	}
}
export default connect(mapStateToProps, mapDispatchtoProps)(MessageBoard)
