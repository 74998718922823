import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Modal, Spin, DatePickerProps, DatePicker, Select, Tooltip, Button } from "antd";
import "../../assets/css/mydata.css";

import { getmyData, updatemyData } from "../../dataServices/Services";
import moment from "moment";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const dateFormat = 'YYYY-MM-DD';
const { Option } = Select;

function UpdateMyData() {
  const { state } = useLocation();
  const { userId } = state || {};
  const history = useHistory();
  const [userData, setUserData] = useState(null);
  const [selectedGoals, setSelectedGoals] = useState([]);
  const [otherOptionValue, setOtherOptionValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const maxDate = moment().endOf('day');

  useEffect(() => {


    const fetchData = async () => {
      try {
        if (userId) {
          const myData = await getmyData(userId);
          setUserData(myData.data);
          setSelectedGoals(myData.data.selectedGoals.map((goal) => goal.id));
          const otherOptionGoal = myData.data.selectedGoals.find((goal) => goal.id === 7);
          if (otherOptionGoal) {
            setOtherOptionValue(otherOptionGoal.goalName);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [userId]);

  //   const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setUserData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: name === "age" && value > 100 ? 100 : value, // Limit age to 100
    }));
  };
  const handleDateChange = (date, dateString) => {
    const calculatedAge = calculateAge(dateString);
    setUserData((prevData) => ({
      ...prevData,
      dateOfBirth: dateString,
      age: calculatedAge
    }));
  };

  const handleSave = async () => {
    try {
      const dataToSend = {
        fullName: userData.fullName,
        sex: userData.sex,
        age: parseInt(userData.age),
        height: parseInt(userData.height),
        currentWeight: parseInt(userData.currentWeight),
        goalWeight: parseInt(userData.goalWeight),
        averageActivityLevel: userData.averageActivityLevel,
        experienceLevel: userData.experienceLevel?.experienceLevel ? userData.experienceLevel?.experienceLevel : userData.experienceLevel,
        selectedGoals: selectedGoals,
        otherOptionValue: selectedGoals.includes(7) ? otherOptionValue : "",
        pushUpTest: parseInt(userData.pushUpTest),
        curlUpTest: parseInt(userData.curlUpTest),
        squatTest: parseInt(userData.squatTest),
        cardioTest: parseInt(userData.cardioTest),
        dateOfBirth: userData.dateOfBirth,
      };
      const data = await updatemyData(userId, dataToSend);
      if (data.error) {
        throw new Error(data.error.response.data.message)
      } else {
        history.goBack();
      }
    } catch (error) {
      if (error) {
        alert(error)
      } else {
        alert("Failed to save data. Please try again.");
      }
    }
  };

  const activityLevels = [
    { value: "Sedentary", label: "Sedentary" },
    { value: "Lightly active", label: "Lightly active" },
    { value: "Moderately active", label: "Moderately active" },
    { value: "Very active", label: "Very active" },
    { value: "Extremely active", label: "Extremely active" },
  ];

  const experienceLevel = [
    { value: "Beginner", label: "Beginner" },
    { value: "Novice", label: "Novice" },
    { value: "Advanced", label: "Advanced" },
    { value: "Athlete", label: "Athlete" }
  ];

  const goals = [
    { id: 8, name: "Lose Weight: Decrease your overall weight, lower your body fat percentage, reduce your BMI" },
    { id: 9, name: "Tone up/Build Muscle: Increase muscle size or firm up muscle for more definition" },
    { id: 1, name: "Increase Energy: feel less sluggish throughout the day and move easier through daily activities" },
    { id: 2, name: "Improve Balance and Flexibility: move easier And with more surety" },
    { id: 3, name: "Increase Performance: Run faster, jump higher, lift more" },
    { id: 4, name: "Live Long In Good Health: Be as healthy as you are able for the long hall" },
    { id: 5, name: "Limit Doctor Visits: Improve health enough that a regular check up is all you need" },
    { id: 6, name: "Enjoy Activities: Play with the kids, go hiking, whatever activity you enjoy, do it without restriction" },
    { id: 7, name: "Other: Something truly important to you" },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    if (selectedGoals.includes(7) && !otherOptionValue) {
      alert("Please specify the other option before submitting.");
      return;
    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Get today's date in YYYY-MM-DD format
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = `${today.getMonth() + 1}`.padStart(2, '0'); // Month is 0-indexed
    const day = `${today.getDate()}`.padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    console.log("ageee", age)
    return age;
  };

  const handleNumberInputChange = (e) => {
    // List of allowed keys
    const allowedKeys = [
      'Backspace',
      'Tab',
      'Shift',
      'ArrowLeft',
      'ArrowRight',
      'ArrowUp',
      'ArrowDown'
    ];
    const isNumberOrDot = /^[0-9.]$/.test(e.key);
    if (!isNumberOrDot && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };


  const handleCheckboxChange = (e) => {
    const goalId = parseInt(e.target.id.slice(1)); // Parse the goal ID

    // Find the goal ID in the selectedGoals array
    const index = selectedGoals.findIndex(goal => goal === goalId);

    if (index > -1) {
      // If found, remove it from the array
      setSelectedGoals(prevSelectedGoals => prevSelectedGoals.filter(id => id !== goalId));
    } else {
      // If not found, add it to the array
      setSelectedGoals(prevSelectedGoals => [...prevSelectedGoals, goalId]);
    }
  };

  const handleKeyPress = (e, goalId) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const checkbox = document.getElementById('g' + goalId);
      checkbox.click();
    }
  };

  return (
    <>
      {userData ? (
        <>
          <div>
            <Modal
              tabIndex={0} // Added tabIndex for accessibility
              className="custom-modal"
              title="My Selected Goals"
              visible={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={null}
            >
              <div className="mydata-page w-100">
                <div className="mydata-form-item">
                  <div className="checkbox-group">
                    {goals.map((goal) => (
                      <div
                        tabIndex={0}
                        key={goal.id}
                        className="checkbox-pair"
                        onKeyPress={(e) => handleKeyPress(e, goal.id)}
                      >
                        <input
                          type="checkbox"
                          id={'g' + goal.id} // Prepend 'g' to make the ID valid
                          name={`goal${goal.id}`}
                          checked={selectedGoals.includes(goal.id)} // Correctly determine if the checkbox should be checked
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor={'g' + goal.id} className="checkbox-label">
                          {goal.name}
                        </label>
                        {goal.id === 7 && selectedGoals.includes(7) && (
                          <input
                            type="text"
                            placeholder="Enter your other goal"
                            value={otherOptionValue}
                            onChange={(e) => setOtherOptionValue(e.target.value)}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="custom-modal-footer">
                <button tabIndex={0} type="button" className="btn btn-primary" onClick={handleOk}>
                  Ok
                </button>
              </div>
            </Modal>
          </div>
          <div className="mydata-page">
            <h1 tabIndex={0}>Update My Data</h1>

            <section className="mydata-stats">
              <p tabIndex={0}>
                Please enter your personal information so that we can better help you with your fitness journey.
              </p>
            </section>

            <section className="mydata-form-wrap">
              <div className="mydata-form rfc-mydata-field-group">
                <div className="mydata-form-item ">
                  <label className="d-block" htmlFor="age">Age</label>
                  <input
                    tabIndex={0}
                    type="number"
                    id="age"
                    name="age"
                    placeholder="years"
                    value={userData?.age || ""}
                    onChange={handleInputChange}
                    readOnly
                    aria-live="polite"
                    aria-readonly="true"
                  />
                </div>
                <div className="mydata-form-item">
                  <label tabIndex={0} className="d-block" htmlFor="sex-male"><span aria-label="required">*</span>Sex</label>
                  <div className="radio-group">
                    <div className="d-flex">
                      <input
                        type="radio"
                        id="sex-male"
                        name="sex"
                        value="male"
                        checked={userData?.sex === "male"}
                        onChange={handleInputChange}
                        aria-label="Male"
                        aria-checked={userData?.sex === 'male'}
                      />
                      <label className="d-block" htmlFor="sex-male">Male</label>
                    </div>
                    <div className="d-flex">
                      <input
                        tabIndex={0}
                        type="radio"
                        id="sex-female"
                        name="sex"
                        value="female"
                        checked={userData?.sex === "female"}
                        onChange={handleInputChange}
                        aria-label="Female"
                        aria-checked={userData?.sex === 'female'}
                      />
                      <label className="d-block" htmlFor="sex-female">Female</label>
                    </div>
                  </div>
                </div>

                <div className="mydata-form-item">
                  <label className="d-block" htmlFor="birthday"><span aria-label="required">*</span>Birthday(mm/dd/yyyy)</label>
                  <DatePicker
                    id="birthday"
                    name="dateOfBirth"
                    value={userData?.dateOfBirth ? moment(userData.dateOfBirth) : ''}
                    onChange={handleDateChange}
                    format="MM/DD/YYYY"
                    disabledDate={(current) => current && current > maxDate}
                    className="border-0 p-0"
                  />
                </div>
                <div className="mydata-form-item">
                  <label className="d-block" htmlFor="height"><span aria-label="required">*</span>Height (inches)</label>
                  <input
                    type="telNo"
                    id="height"
                    name="height"
                    maxlength="5"
                    value={userData?.height || ""}
                    onChange={handleInputChange}
                    onKeyDown={handleNumberInputChange}
                  />
                </div>
                <div className="mydata-form-item">
                  <label className="d-block" htmlFor="current-weight"><span aria-label="required">*</span>Current Weight (lbs)</label>
                  <input
                    type="telNo"
                    id="current-weight"
                    name="currentWeight"
                    placeholder="68 lbs"
                    maxlength="5"
                    value={userData?.currentWeight || ""}
                    onChange={handleInputChange}
                    onKeyDown={handleNumberInputChange}
                  />
                </div>
                <div className="mydata-form-item">
                  <label tabIndex={0} className="d-block" htmlFor="goal-weight">Goal Weight (lbs)
                    <Tooltip trigger="focus" placement="topLeft" color="white" title={
                      <span aria-live="polite">
                        {"Selecting a goal weight is a very personal choice, and can be different for each individual based on several factors. The ‘My Stats’ page provides you with an ideal body weight suggestion based on the Body Mass Index, which is generally used to determine optimal body weight for a particular height. Some personal factors that can change your goal weight number may include things like sporting events where you may be better served maintaining a higher or lower weight, eating disorders where you have struggled with your eating practices, medical conditions, or any other personal factor. You may even have a goal of improving your health and fitness while simply maintaining your current weight, not looking to either gain nor lose weight. Ultimately, when choosing your goal weight, it is important to remember that there are several other measurements used to determine overall health, such as Body Composition, Strength, Flexibility, Sleep Quality, Hydration Level, etc., and that no matter what number you choose, it is important to always be kind to yourself. Note, if you do not have a weight loss or weight gain goal, you can leave this field blank or input your current weight."}
                      </span>}>
                      <Button type="text">
                        <i className="fas fa-info-circle" />
                      </Button>
                    </Tooltip>
                  </label>
                  <input
                    type="telNo"
                    id="goal-weight"
                    name="goalWeight"
                    placeholder="68"
                    maxLength="5"
                    value={userData?.goalWeight || ""}
                    onChange={handleInputChange}
                    onKeyDown={handleNumberInputChange}
                  />
                </div>
                <div className="mydata-form-item">
                  <label className="d-block" htmlFor="activity-level">Average Activity Level</label>
                  <Select
                    className="d-block"
                    id="activity-level"
                    name="averageActivityLevel"
                    value={userData?.averageActivityLevel || ""}
                    onChange={(value) => handleInputChange({ target: { name: 'averageActivityLevel', value } })}
                    aria-label="Average Activity Level"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {activityLevels.map((level, index) => (
                      <Option key={level.value} value={level.value} aria-posinset={index + 1}
                        aria-setsize={activityLevels.length}
                        aria-selected={userData?.averageActivityLevel === level.value}
                      >
                        {level.label}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="mydata-form-item">
                  <label className="d-block" htmlFor="experience-level">My Experience Level</label>
                  <Select
                    className="d-block"
                    id="experience-level"
                    name="experienceLevel"
                    value={
                      userData?.experienceLevel?.experienceLevel
                        ? userData.experienceLevel.experienceLevel
                        : userData?.experienceLevel || ""
                    }
                    onChange={(value) => handleInputChange({ target: { name: 'experienceLevel', value } })}
                    aria-label="Experience Level"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {experienceLevel.map((level, index) => (
                      <Option
                        key={level.value}
                        value={level.value}
                        aria-posinset={index + 1}
                        aria-setsize={experienceLevel.length}
                        aria-selected={
                          userData?.experienceLevel?.experienceLevel === level.value ||
                          userData?.experienceLevel === level.value
                        }
                      >
                        {level.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </section>

            {/* <section>
            <button type="button" className="btn btn-primary btn-block" onClick={showModal}>
              <p>My Selected Goals</p>
              <span>&rsaquo;</span>
            </button>
            <h3 className="rfc-title-text">Selected Goels</h3>
            <div className="rfc-chip-data-container">
              <span>Increase Energy: feel less sluggish throughout the day and move easier through daily activities</span>
              <span>Increase Energy: feel less sluggish throughout the day and move easier through daily activities</span>
              <span>Increase Energy: feel less sluggish throughout the day and move easier through daily activities</span>
              <span>Increase Energy: feel less sluggish throughout the day and move easier through daily activities</span>
              <span>Increase Energy: feel less sluggish throughout the day and move easier through daily activities</span>
              <span>Increase Energy: feel less sluggish throughout the day and move easier through daily activities</span>
              <span>Increase Energy: feel less sluggish throughout the day and move easier through daily activities</span>
            </div>
          </section> */}
            <section>
              <button type="button" className="btn btn-primary btn-block" onClick={showModal}>
                <p>My Selected Goals</p>
                <span>&rsaquo;</span>
              </button>
              <h3 className="rfc-title-text">Selected Goals</h3>
              <div className="rfc-chip-data-container">
                {selectedGoals.map((goalId) => {
                  const goal = goals.find((g) => g.id === goalId);
                  const displayText = goalId === 7 ? otherOptionValue : goal?.name;

                  // Render the span only if displayText is truthy
                  return displayText ? <span key={goalId}>{displayText}</span> : null;
                })}
              </div>
            </section>

            <section className="mydata-stats">
              <h4 className="mydata-stats-title">My Fitness Tests</h4>
              <p>
                This section contains some personal information so we can better help you with your fitness journey. Know that
                this information is only ever seen by you.
              </p>
            </section>

            <section className="mydata-form-wrap">
              <div className="mydata-form rfc-mydata-field-group">
                <div className="mydata-form-item">
                  <label className="d-block" htmlFor="pushup-test">1 Minute Push-up Fitness Test (reps)</label>
                  <input
                    type="telNo"
                    id="pushup-test"
                    placeholder="Reps"
                    maxLength={5}
                    value={userData?.pushUpTest || ""}
                    onChange={handleInputChange}
                    onKeyDown={handleNumberInputChange}
                    name="pushUpTest"
                  />
                </div>
                <div className="mydata-form-item">
                  <label className="d-block" htmlFor="curlup-test">1 Minute Curl-up Fitness Test (reps)</label>
                  <input
                    type="telNo"
                    maxLength={5}
                    id="curlup-test"
                    placeholder="Reps"
                    value={userData?.curlUpTest || ""}
                    onChange={handleInputChange}
                    onKeyDown={handleNumberInputChange}
                    name="curlUpTest"
                  />
                </div>
                <div className="mydata-form-item">
                  <label className="d-block" htmlFor="squat-test">1 Minute Squat Fitness Test (reps)</label>
                  <input
                    type="telNo"
                    maxLength={5}
                    id="squat-test"
                    placeholder="Reps"
                    value={userData?.squatTest || ""}
                    onChange={handleInputChange}
                    onKeyDown={handleNumberInputChange}
                    name="squatTest"
                  />
                </div>
                <div className="mydata-form-item">
                  <label className="d-block" htmlFor="cardio-test">3 Minute YMCA Cardio Fitness Test (bpm)</label>
                  <input
                    type="telNo"
                    maxLength={5}
                    id="cardio-test"
                    placeholder="bpm"
                    value={userData?.cardioTest || ""}
                    onChange={handleInputChange}
                    onKeyDown={handleNumberInputChange}
                    name="cardioTest"
                  />
                </div>
              </div>
            </section>
          </div>
          <button type="button" className="btn btn-primary" onClick={handleSave}>
            Save Changes
          </button>
        </>
      ) : (
        <div className="loader">
          <Spin />
        </div>
      )
      }
    </>
  );
}

export default UpdateMyData;
