/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react'
import { loadStudiosService, reorderStudioService } from '../../dataServices/Services'
import { getStudios } from '../../redux/actions/studioAction'
import { connect } from 'react-redux'
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

import { NETWORK_ERROR, BASE_URL } from '../../constants/Constants'
import Axios from 'axios'
import FilterResults from 'react-filter-search'
function Studios({ getStudios, history, auth }) {
	const [ studios, setStudios ] = useState([])
	const [ loading, setLoading ] = useState(false)
	const [ searchValue, setsearchValue ] = useState('')
	const [ searchData, setSearchData ] = useState([])

	const [ categories, setCategories ] = useState([])
	const fetchStudios = async () => {
		setLoading(true)
		let r = await loadStudiosService()

		if (r !== NETWORK_ERROR) {
			setLoading(false)
			setStudios(r.data.studios)
			getStudios(r.data.studios)
		}
	}
	const getCategory = () => {
		let cat = []
		for (let i = 0; i < studios.length; i++) {
			if (studios.length > 0) {
				if (cat.indexOf(studios[ i ].category.toLowerCase()) === -1) {
					cat.push(studios[ i ].category.toLowerCase())
				}
			}
		}

		setCategories(cat)
	}
	useEffect(() => {
		fetchStudios()
	}, [])
	useEffect(() => {
		getCategory()
	}, [])

	const reorderStudio = async (currentOrder, newOrder) => {
		const data = {
			currentOrder: studios[ currentOrder ].order,
			newOrder: studios[ newOrder ].order
		}
		await reorderStudioService(data).then((data) => {
			fetchStudios()
		})
	}
	const handleClearSearch = () => {
    setsearchValue('');
	setSearchData([]);
  };

	return (
		<div className="m-4">
			<div className="top-bar">
				{' '}
				<div className="top-bar-search">
					{' '}
					<div className="custom-input mr-3">
					<input
						type="text"
						name=""
						id=""
						value={searchValue}
						placeholder="Search"
						aria-label="Search studio by studio name"
						onChange={(e) => {
							setsearchValue(e.target.value)
							setSearchData(studios)
						}}
					/>
					<button onClick={handleClearSearch} aria-label="Clear search data">
					<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
<path d="M24.2037 3.88328C23.7481 3.42766 23.0094 3.42766 22.5538 3.88328L14.0437 12.3934L5.53362 3.88328C5.07801 3.42766 4.33931 3.42766 3.88371 3.88328C3.42809 4.33889 3.42809 5.07758 3.88371 5.53319L12.3938 14.0433L3.88373 22.5533C3.42811 23.009 3.42811 23.7476 3.88373 24.2033C4.33934 24.6589 5.07803 24.6589 5.53364 24.2033L14.0437 15.6932L22.5538 24.2033C23.0094 24.6589 23.7481 24.6589 24.2037 24.2033C24.6593 23.7476 24.6593 23.009 24.2037 22.5534L15.6936 14.0433L24.2037 5.53319C24.6593 5.07758 24.6593 4.33889 24.2037 3.88328Z"/>
</svg>
					</button>
					</div>
					{' '}
					<select className="dropdown-new"
						name=""
						id=""
						aria-label="Search Studios by category "
						value={searchValue}
						onChange={(e) => {
							setsearchValue(e.target.value)
							setSearchData(studios)
						}}
						
					>
						{categories &&
							categories.map((cat) => <option value={cat}>{cat}</option>)}
					</select>
					{searchData && (
						<FilterResults
							value={searchValue}
							data={searchData}
							renderResults={(results) => (
								<div className="w-100">
									{results.map((el) => (
										<div className="select-custom"
											onClick={(e) => {
												history.push(`/studios/${el._id}`)
												setSearchData([])
											}}
											onKeyPress={(e) => {
												history.push(`/studios/${el._id}`)
												setSearchData([])
											}}
										>
											<div>
												<p className='mb-0'
													tabIndex={0}
													
												>
													{el.title} : Category {el.category}
												</p>
											</div>
										</div>
									))}
								</div>
							)}
						/>
					)}
				</div>
			</div>
			<h1 tabIndex={0} aria-label="Welcome to Fitness Studio ">
				Welcome in to the Fitness Studio
			</h1>
			<p tabIndex={0}>
				Here you can get yourself moving through a variety of fully accessible
				studio sessions.
			</p>
			{auth.user.isTrainer && (
				<button
					onClick={(e) => {
						history.push('/addStudio')
					}}
					className="btn btn-primary"
				>
					Add Studio
				</button>
			)}
			<h4 tabIndex={0}>List of fitness studios</h4>
			<div aria-label="List of Studios" className="list-group">
				{' '}
				{loading && (
					<div
						class="spinner-border text-primary ml-auto mr-auto"
						role="status"
					>
						<span class="sr-only">Loading...</span>
					</div>
				)}
				{studios &&
					!auth.user.isTrainer &&
					studios.map((s) => {
						return (
							<button
								key={s._id}
								tabIndex={0}
								onClick={() => {
									history.push(`/studios/${s._id}`)
								}}
								className="btn btn-primary"
								style={{
									marginTop: '5px',
									display: 'flex',
									flexWrap: 'wrap',
								}}
							>
								{s.title}
							</button>
						)
					})}
				{auth.user.isTrainer &&
					studios &&
					studios.map((s, index) => {
						return (
							<div style={{ display: 'flex' }}>
								{' '}
								<button
									key={s._id}
									tabIndex={0}
									aria-label={`${s.title}-position ${index+1}`}
									onClick={() => {
										history.push(`/studios/${s._id}`)
									}}
									className="btn btn-primary m-2"
									style={{
										marginTop: '5px',
										width: '90%',
									}}
								>
									{s.title}
								</button>
								{index !== 0 && (
									<button
										aria-label="Move Up The Studio"
										className="btn btn-danger m-2"
										onClick={() => reorderStudio(index, index - 1)}
									>
										<ArrowUpOutlined />
									</button>
								)}
								{index !== (studios.length - 1) && (
									<button
										aria-label="Move Down The Studio"
										className="btn btn-danger m-2"
										onClick={() => reorderStudio(index, index + 1)}
									>
										<ArrowDownOutlined />
									</button>
								)}
								<button
									className="btn btn-danger m-2"
									onClick={(e) => {
										e.preventDefault()
										let token = localStorage.getItem('token')
										let config = {
											headers: { Authorization: 'Bearer ' + token },
										}
										Axios.delete(`${BASE_URL}studios/${s._id}`, config)
											.then((res) => {
												console.log(res)
												if (res.status === 200) {
													alert(res.data.message)
													fetchStudios()
												}
											})
											.catch((e) => {
												console.log(e)
											})
									}}
									aria-label="Delete Studio"
								>
									<i className="fa fa-trash"></i>
								</button>
								<button
									className="btn btn-danger m-2"
									onClick={(e) => {
										history.push(`/editStudio/${s._id}`)
									}}
									aria-label="Edit Studio"
								>
									<i className="fa fa-pencil"></i>
								</button>
							</div>
						)
					})}
			</div>
		</div>
	)
}
const mapDispatchToProps = (dispatch) => {
	return {
		getStudios: (classrooms) => {
			dispatch(getStudios(classrooms))
		},
	}
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(Studios)
