import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { LeftOutlined } from '@ant-design/icons';
import "../../assets/css/credits.css";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from "antd";
import { useToasts } from "react-toast-notifications";
import "../../assets/css/credits.css";
import { connect } from 'react-redux';

import {
    deleteCredits, getCreditsById
} from "../../dataServices/Services";
import { deleteBody, successConstants, status } from "../../constants/Constants";
import Routes from "../../constants/routes";


const ViewCredits = ({ auth }) => {
    const { state: { record } } = useLocation();
    const { _id } = record;
    const history = useHistory();
    const [showFull, setShowFull] = useState(false);
    const [description, setDescriptionToShow] = useState("");
    const [title, setTitleToShow] = useState("");
    const { addToast } = useToasts();
    const { confirm } = Modal;

    const toggleShowFull = () => {
        setShowFull(!showFull);
    };

    const showDeleteConfirm = (id) => {
        confirm({
            title: deleteBody.title,
            icon: <ExclamationCircleFilled />,
            content: deleteBody.message,
            okText: <span aria-label="Delete Confirmation. Are you sure. yes" tabIndex={0}>
                Yes
            </span>,
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                await deleteCredits(id);
                history.goBack()
                addToast(successConstants.credit.delete, {
                    appearance: status.success,
                    autoDismiss: true,
                })
            },
        });
    };

    const fetchUpdatedDescription = async () => {
        try {
            const response = await getCreditsById(_id);
            const updatedDescription = response.description;
            const updatedTitle = response.title;
            setTitleToShow(updatedTitle);
            setDescriptionToShow(updatedDescription);
        } catch (error) {
            console.error("Error fetching updated description:", error);
        }
    };

    useEffect(() => {
        fetchUpdatedDescription();
    }, []);

    return (
        <>
            <h1 tabIndex={0}>View Credit</h1>
            <div className="d-flex justify-content-center m-2">
                <div className="card cardBlock" tabIndex={0}>
                    <div className="card-body">
                        <div className="card-title">
                            <h5 tabIndex={0}>Title</h5>
                        </div>
                        <p tabIndex={0}>{title}</p>
                        <h5 tabIndex={0}>Description</h5>
                        <p className="card-text" tabIndex={0}>{description}</p>
                        {description.length > 500 && (
                            <button
                                className="btn btn-link"
                                type="button"
                                onClick={toggleShowFull}
                                tabIndex={0}
                                aria-label={showFull ? "Read Less" : "Read More"}
                            >
                                {showFull ? 'Read Less' : 'Read More'}
                            </button>
                        )}
                        {auth.user.isTrainer && <>
                            <button aria-label="delete pushNotification" className="btn btn-danger m-2"
                                onClick={() => showDeleteConfirm(_id)}>
                                <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>
                            <button aria-label="Edit pushNotification" className="btn btn-danger m-2"
                                onClick={() => history.push({
                                    pathname: Routes.editCredits,
                                    state: { record: { ...record, description: description, title: title } } // Include description in the record
                                })}>
                                <i className="fa fa-pencil"></i>
                            </button>
                        </>
                        }
                    </div>
                </div>
            </div>
            <button type="button" className="btn btn-primary m-2" onClick={() => history.goBack()} tabIndex={0}>
                <LeftOutlined /> Back
            </button>
        </>
    );
}


const mapStateToProps = (state) => ({
    auth: state.auth,
})
export default connect(mapStateToProps)(ViewCredits)