/* eslint-disable react-hooks/exhaustive-deps */
import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import AudioPlayer from 'react-h5-audio-player'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { BASE_URL, NETWORK_ERROR } from '../../constants/Constants'
import {
	addClassroomFav,
	loadClassroomsService,
	loadSingleClassroom,
	loadSingleClassroomFav,
	removeClassroomFav,
	reportComment,
} from '../../dataServices/Services'
import isEmpty from '../../validation/is-empty'
import TextFieldGroup from '../common/TextFieldGroup'

import { getClassrooms } from '../../redux/actions/classActions'
import SingleComment from './SingleComment'
function SingleClassroom({ auth, getClassrooms }) {
	let token = localStorage.getItem('token')
	const config = {
		headers: { Authorization: `Bearer ${token}` },
	}
	const { addToast } = useToasts()
	const [fav, setFav] = useState({})
	const [showComments, setShowComments] = useState(false)
	const [currentUserLike, setCurrentUserLike] = useState(false)
	const [classroom, setClassroom] = useState({})
	const [comment, setCommentText] = useState('')
	const [like, setLike] = useState('')
	const { id } = useParams()
	const [showFull, setShowFull] = useState(false)
	let description = classroom.description
	const firstPart = description && description.slice(0, 180)
	const lastPart = description && description.slice(180, description.length)
	const deleteComment = async (classId, commentId) => {
		try {
			Axios({
				method: 'delete',
				url: `${BASE_URL}classrooms/comments/remove/${classId}/${commentId}`,
				headers: { Authorization: `Bearer ${token}` },
			}).then((res) => {
				alert('Comment Deleted ')
			})
			let r = await loadClassroomsService()
			getClassrooms(r.data.classrooms)
			fetchClassroom()
		} catch {
			alert('Comment not delelted ')
		}
	}

	const handleReportComment = async (classId, commentId) => {
		try {
			await reportComment(classId, commentId)
			let r = await loadClassroomsService()
			getClassrooms(r.data.classrooms)
			fetchClassroom()
			alert('Comment reported ')
		} catch {
			alert('Comment already reported ')
		}
	}

	const fetchClassroom = async () => {
		let r = await loadSingleClassroom(id)
		setCurrentUserLike(
			Boolean(
				r.data.classroom.likes.find(function (l) {
					return l.userId === auth.user._id
				})
			)
		)
		setLike(
			r.data.classroom.likes.filter(function (l) {
				return l.userId === auth.user._id
			})[0]
		)

		if (r.status === 200) {
			setClassroom(r.data.classroom)
			console.log(r.data.classroom)
		}
	}
	const fetchClassroomFav = async () => {
		let r = await loadSingleClassroomFav(auth.user._id, id)

		if (r !== NETWORK_ERROR) {
			if (r.data.favorite) {
				setFav(r.data.favorite)
			} else {
				setFav({})
			}
		}
	}
	useEffect(() => {
		fetchClassroom()
		fetchClassroomFav()
	}, [])
	return (
		<div>
			{classroom ? (
				<div className="d-flex justify-content-center m-2">
					{' '}
					<div className="card" style={{ width: '100%' }}>
						<div className="card-body">
							<h1
								tabIndex={0}
								style={{ display: 'block' }}
								aria-label="Classrooms"
							>
								Welcome into the Classroom
							</h1>{' '}
							<h5 className="card-title" tabIndex={0} aria-label="">
								{classroom.title}
							</h5>
							<p
								className="card-text"
								tabIndex={0}
								aria-label={showFull ? firstPart + lastPart : firstPart}
							>
								{firstPart}
								{!showFull && <span>...</span>}
								{showFull && lastPart}
								<button
									className="btn btn-link"
									type="button"
									aria-label={
										!showFull ? 'Read More Button' : 'Read less Button'
									}
									onClick={() => setShowFull(!showFull)}
								>
									{showFull ? 'Read less' : 'Read More'}
								</button>{' '}
							</p>
							<div className="card-text">
								<h5 tabIndex={0}>Audio Description</h5>
								{console.log(classroom.description)}
								{classroom && classroom.audio ? (
									<div
										style={{
											marginBottom: '10px',
										}}
									>
										<AudioPlayer
											src={`${classroom.audio}`}
											progressJumpSteps={{ backward: 15000, forward: 15000 }}
										/>
									</div>
								) : (
									'No Audio'
								)}
							</div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								{' '}
								{isEmpty(fav) ? (
									<button
										className="btn btn-danger m-1"
										aria-label="Add in favorites"
										onClick={async (e) => {
											e.preventDefault()
											let fav = {
												userId: auth.user._id,
												refId: id,
												name: classroom.title,
											}

											await addClassroomFav(fav)
											addToast('Classroom added to Favorites', {
												appearance: 'success',
												autoDismiss: true,
											})
											fetchClassroomFav()
										}}
									>
										<i className="fa fa-heart"></i>
									</button>
								) : (
									<button
										className="btn btn-danger m-1"
										aria-label="Remove from favorites"
										onClick={async (e) => {
											e.preventDefault()
											await removeClassroomFav(fav._id)
											addToast('Classroom removed from favorites', {
												appearance: 'error',
												autoDismiss: true,
											})
											fetchClassroomFav()
										}}
									>
										<i className="fa fa-heart-o"></i>
									</button>
								)}
								{currentUserLike ? (
									<button
										className="btn btn-primary "
										aria-label="Dis Like classroom"
										onClick={async (e) => {
											Axios({
												method: 'delete',
												url: `${BASE_URL}classrooms/likes/remove/${classroom._id}/${like._id}`,
												headers: { Authorization: `Bearer ${token}` },
											}).then((res) => {
												setCurrentUserLike(false)
											})
											let r = await loadClassroomsService()
											getClassrooms(r.data.classrooms)
											fetchClassroom()
										}}
									>
										{' '}
										<i className="fa fa-thumbs-down"></i>{' '}
										<span className="m-1 ml-3">
											{classroom.likes && classroom.likes.length}
										</span>
									</button>
								) : (
									<button
										className="btn btn-primary "
										aria-label="Like Classroom"
										onClick={async () => {
											Axios.post(
												`${BASE_URL}classrooms/likes/add/${classroom._id}`,
												{ userId: auth.user._id },
												config
											)
											let r = await loadClassroomsService()
											getClassrooms(r.data.classrooms)
											fetchClassroom()
										}}
									>
										{' '}
										<i className="fa fa-thumbs-up"></i>{' '}
										<span className="m-1 ml-3">
											{classroom.likes && classroom.likes.length}
										</span>
									</button>
								)}{' '}
								<button
									className="btn btn-primary m-1"
									aria-label="Show Comments"
									onClick={() => {
										setShowComments(!showComments)
									}}
								>
									<i className="fa fa-comment"></i>
									<span className="m-1 ml-3">
										{classroom.comments && classroom.comments.length}
									</span>
								</button>
							</div>
							{showComments && (
								<div>
									{classroom.comments && classroom.comments.length > 0 ? (
										<div className=" border m-2 p-3">
											<div aria-label="Add new Comment Section">
												{' '}
												<TextFieldGroup
													name="comment"
													type="text"
													value={comment}
													onChange={(e) => {
														setCommentText(e.target.value)
													}}
													placeholder="Add Comment"
												/>
												<br></br>
												<button
													className="btn btn-secondary"
													aria-label="Add New Comment"
													onClick={async () => {
														if (comment.trim() !== '') {
															Axios.post(
																`${BASE_URL}classrooms/comments/add/${classroom._id}`,
																{
																	userId: auth.user._id,
																	text: comment,
																	firstName: auth.user.firstName,
																	lastName: auth.user.lastName,
																},
																config
															)
															let r = await loadClassroomsService()
															getClassrooms(r.data.classrooms)
															setCommentText("")
															fetchClassroom()
														}
													}}
												>
													Add Comment
												</button>
											</div>

											<hr></hr>
											<h5>Comments</h5>
											{classroom.comments.map((c) => {
												return (
													<SingleComment
														name={c.firstName + ' ' + c.lastName}
														text={c.text}
														time={Date.parse(c.createdAt)}
														userId={auth.user._id}
														classId={classroom._id}
														commentId={c._id}
														isCommentAlreadyReported={!c?.reportedBy?.includes(auth.user._id)}
														commentUserId={c.userId}
														delete={deleteComment}
														reportComment={handleReportComment}
													/>
												)
											})}
										</div>
									) : (
										<div className="border m-2 p-3">
											{' '}
											<TextFieldGroup
												name="comment"
												type="text"
												value={comment}
												onChange={(e) => {
													setCommentText(e.target.value)
												}}
												placeholder="Add Comment"
											/>
											<br></br>
											<button
												className="btn btn-secondary"
												aria-label="Add New Comment"
												onClick={async () => {
													if (comment.trim() !== '') {
														Axios.post(
															`${BASE_URL}classrooms/comments/add/${classroom._id}`,
															{
																userId: auth.user._id,
																text: comment,
																firstName: auth.user.firstName,
																lastName: auth.user.lastName,
															},
															config
														)
														let r = await loadClassroomsService()
														getClassrooms(r.data.classrooms)
														setCommentText("")
														fetchClassroom()
													}
												}}
											>
												Add Comment
											</button>
										</div>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			) : (
				<h1>Not Found</h1>
			)}
		</div>
	)
}
const mapStateToProps = (state) => {
	return {
		auth: state.auth,
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		getClassrooms: (classrooms) => {
			dispatch(getClassrooms(classrooms))
		},
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(SingleClassroom)
